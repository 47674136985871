export const locale = {
  triggerDesc: 'Kliknite da sortirate silazno',
  triggerAsc: 'Kliknite da sortirate uzlazno',
  cancelSort: 'Kliknite da otkažete sortiranje',
  // Options.jsx
  items_per_page: '',
  jump_to: 'Idi na',
  jump_to_confirm: 'potvrdi',
  page: '',
  // Pagination.jsx
  prev_page: 'Prethodna stranica',
  next_page: 'Sljedeća stranica',
  prev_5: 'Prethodnih 5 stranica',
  next_5: 'Sljedećih 5 stranica',
  prev_3: 'Prethodne 3 stranice',
  next_3: 'Sljedeće 3 stranice',
};
