import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import api from '../../util/api';

import {
  setCurrentRadilisteNovi,
  setCurrentRadilisteStari,
} from '../../redux/radiliste/radiliste.actions';

import customStyles from '../../util/selectCustomStyle';

import Select from 'react-select';

import './radiliste.styles.scss';

const Radiliste = ({
  currentRadilisteNovi,
  currentRadilisteStari,
  setCurrentRadilisteNovi,
  setCurrentRadilisteStari,
}) => {
  const navigate = useNavigate();

  const [optionsKopKo, setOptionsKopKo] = useState([]);
  const [optionsGruKo, setOptionsGruKo] = useState([]);

  useEffect(() => {
    if (optionsKopKo.length === 0) {
      setCurrentRadilisteNovi('');
    }
    if (optionsGruKo.length === 0) {
      setCurrentRadilisteStari('');
    }
    let kopPlaceholderArray = [];
    let gruPlaceholderArray = [];

    // Get Kat KO select options
    try {
      api.get('/table/kop_ko').then((response) => {
        const result = response.data.data;
        if (result !== 'No data.' && result) {
          result.map((opt) => {
            return kopPlaceholderArray.push({
              value: opt.naziv,
              label: opt.naziv,
            });
          });
          setOptionsKopKo(kopPlaceholderArray);
        }
      });
    } catch (error) {
      console.log('error: ', error);
    }

    // Get Gru KO select options
    try {
      api.get('/table/gru_ko').then((response) => {
        const result = response.data.data;
        if (result !== 'No data.' && result) {
          result.map((opt) => {
            return gruPlaceholderArray.push({
              value: opt.naziv,
              label: opt.naziv,
            });
          });
          setOptionsGruKo(gruPlaceholderArray);
        }
      });
    } catch (error) {
      console.log('error: ', error);
    }
  }, [
    optionsGruKo.length,
    optionsKopKo.length,
    setCurrentRadilisteNovi,
    setCurrentRadilisteStari,
  ]);

  const doSelectStari = (event) => {
    if (event) {
      setCurrentRadilisteStari(event.value);
    }
  };

  const doSelectNovi = (event) => {
    if (event) {
      setCurrentRadilisteNovi(event.value);
    }
  };

  const checkInputs = (event) => {
    event.preventDefault();
    if (currentRadilisteStari !== '' && currentRadilisteNovi !== '') {
      navigate('/unos');
    } else {
      console.log('selecti nisu odabrani');
    }
  };

  return (
    <div className='container-fluid radiliste'>
      <div className='row'>
        <form className='d-flex' onSubmit={checkInputs}>
          <div className='select-wrapper me-3'>
            <Select
              placeholder='KO Novi premjer'
              styles={customStyles}
              width='190px'
              menuColor='#333'
              name='koNoviPremjer'
              noOptionsMessage={() => 'Odaberite KO Novog premjera'}
              options={optionsKopKo}
              onChange={doSelectNovi}
            />
          </div>
          <div className='select-wrapper'>
            <Select
              placeholder='KO Stari premjer'
              styles={customStyles}
              width='190px'
              menuColor='#333'
              name='koStariPremjer'
              noOptionsMessage={() => 'Odaberite KO Starog premjera'}
              options={optionsGruKo}
              onChange={doSelectStari}
            />
          </div>
          <button type='submit' className='btn btn-secondary mx-3'>
            NASTAVI DALJE
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentRadilisteStari: state.radiliste.currentRadilisteStari,
  currentRadilisteNovi: state.radiliste.currentRadilisteNovi,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentRadilisteNovi: (rn) => dispatch(setCurrentRadilisteNovi(rn)),
  setCurrentRadilisteStari: (rs) => dispatch(setCurrentRadilisteStari(rs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radiliste);
