export const PlsActionsTypes = {
  SET_ACTIVE_PLS: 'SET_ACTIVE_PLS',
  SET_CURRENT_PLS: 'SET_CURRENT_PLS',
  SET_CURRENT_PLSOD: 'SET_CURRENT_PLSOD',
  SET_CURRENT_PLSDO: 'SET_CURRENT_PLSDO',
  ADD_LAST_PL: 'ADD_LAST_PL',
  SET_IDENT_KAT: 'SET_IDENT_KAT',
  SET_IDENT_GRU: 'SET_IDENT_GRU',
  SET_STATUS_KAT: 'SET_STATUS_KAT',
  SET_STATUS_GRU: 'SET_STATUS_GRU',
};
