import { GruzkActionsTypes } from './gruzk.types';

export const setCurrentGruzk = (gruzk) => ({
  type: GruzkActionsTypes.SET_CURRENT_GRUZK,
  payload: gruzk,
});

export const setUpdateGruzk = (gruzk) => ({
  type: GruzkActionsTypes.UPDATE_GRUZK,
  payload: gruzk,
});
