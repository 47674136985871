import React from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCurrentUser } from '../../redux/user/user.selectors';

import Radiliste from '../../components/radiliste/radiliste.component';

import './homepage.styles.scss';

const HomePage = ({ currentUser }) => (
  <div className='container-fluid homepage d-flex justify-content-center'>
    {currentUser ? (
      <div className='odabir-radilista'>
        <h1 className='text-center text-white mb-5'>ODABIR RADILIŠTA</h1>
        <Radiliste />
      </div>
    ) : (
      <h1 className='text-center text-white'>
        Da bi ste započeli sa radom morate se prijaviti
      </h1>
    )}
  </div>
);

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(HomePage);
