import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Drawer, Table, ConfigProvider } from 'antd';

import api from '../../util/api';
import { locale } from '../../locale/bs_BA';
import { customizeRenderEmpty } from '../../util/customizeEmpty';
import { setVisibleDrawer } from '../../redux/radiliste/radiliste.actions';

import TableAListPregled from '../../components/antd-table-a-list-pregled/antd-table-a-list-pregled.components';
import { useLocationChange } from '../../util/locationChange';

import './pregled.styles.scss';

const AllParcele = ({
  identKat,
  identGru,
  visibleDrawer,
  setVisibleDrawer,
  currentRadilisteNovi,
  currentRadilisteStari,
}) => {
  // * Koristim da na promjenu lokacije daodajem class na body
  useLocationChange((location) => {
    if (location.pathname === '/pregled') {
      document.body.classList.add('pregled-body');
    } else {
      document.body.classList.remove('pregled-body');
    }
  });

  // * GET podataka KAT parcela
  const [dataKatParcAPI, setDataKatParcAPI] = useState([]);
  useEffect(() => {
    if (currentRadilisteNovi) {
      try {
        api
          .get(
            `/table/kop_parc_dio/?limit=99999&where=ko="${currentRadilisteNovi}"`
          )
          .then((response) => {
            const result = response.data.data;
            if (result === 'No data.' || !result) {
              setDataKatParcAPI([]);
            } else {
              setDataKatParcAPI(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [currentRadilisteNovi]);

  // * GET podataka GRU parcela
  const [dataGruParcAPI, setDataGruParcAPI] = useState([]);
  useEffect(() => {
    if (currentRadilisteStari) {
      try {
        api
          .get(
            `/table/gru_parc_dio/?limit=99999&where=ko="${currentRadilisteStari}"`
          )
          .then((response) => {
            const result = response.data.data;
            if (result === 'No data.' || !result) {
              setDataGruParcAPI([]);
            } else {
              setDataGruParcAPI(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [currentRadilisteStari]);

  // * GET podataka za parcele koje su identifikovane a nema ih u KOP podacima kod drugog preuzimanja
  const [symetricDifference, setSymetricDifference] = useState([]);
  useEffect(() => {
    if (currentRadilisteNovi) {
      try {
        api
          .get(
            `/table/kop_a_list/?limit=99999&columns=broj_parcele&where=ko="${currentRadilisteNovi}"`
          )
          .then((response) => {
            const res = response.data.data;
            const dataFS = res
              .map((x) => x.broj_parcele)
              .sort((a, b) =>
                a.localeCompare(b, undefined, {
                  numeric: true,
                  sensitivity: 'base',
                })
              );
            const identFS = identKat
              .map((x) => x.parc)
              .sort((a, b) =>
                a.localeCompare(b, undefined, {
                  numeric: true,
                  sensitivity: 'base',
                })
              );

            if (res !== 'No data.' && res) {
              let difference = identFS
                .filter((x) => !dataFS.includes(x))
                .concat(dataFS.filter((x) => !identFS.includes(x)));

              let differencePL = identKat.filter((x) =>
                difference.includes(x.parc)
              );

              let ukojemPL = differencePL.map((x) => x.broj);

              if (ukojemPL.length > 0) {
                try {
                  api
                    .get(
                      `/table/prijavni_list/?where=broj=${ukojemPL} and kat_ko="${currentRadilisteNovi}"`
                    )
                    .then((response) => {
                      const res = response.data.data;
                      let samoPogresna = res.filter((x) =>
                        difference.includes(x.broj_parcele_kop)
                      );
                      if (res !== 'No data.' && res) {
                        setSymetricDifference(samoPogresna);
                      }
                    });
                } catch (err) {
                  console.log('ukojempl error: ', err);
                }
              }
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [currentRadilisteNovi, identKat]);

  // * Priprema podataka za antd-table-a-list KOP
  const dataKatA = dataKatParcAPI?.map((row, i) => ({
    key: i,
    broj_parcele: row.broj_parcele,
    naziv_parcele: row.naziv_parcele,
    povrsina_parcele: row.povrsina_parcele,
    nacin_koristenja: row.nacin_koristenja,
    povrsina_dijela_parcele: row.povrsina_dijela_parcele,
    tags: identKat.find((x) => x.parc === row.broj_parcele) ? 1 : 0,
  }));

  // * Priprema podataka za antd-table-a-list KOP
  const dataGruA = dataGruParcAPI?.map((row, i) => ({
    key: i,
    broj_parcele: row.broj_parcele,
    naziv_parcele: row.naziv_parcele,
    povrsina_parcele: row.povrsina_parcele,
    nacin_koristenja: row.nacin_koristenja,
    povrsina_dijela_parcele: row.povrsina_dijela_parcele,
    tags: identGru.find((x) => x.parc === row.broj_parcele) ? 1 : 0,
  }));

  // * Priprema podataka za antd-table-drawer
  const dataDrawer = symetricDifference?.map((row, i) => ({
    key: i,
    broj: row.broj,
    broj_parcele_kop: row.broj_parcele_kop,
    broj_parcele_grunt: row.broj_parcele_grunt,
    kat_povrsina_parc: row.kat_povrsina_parc,
    gru_povrsina_parc: row.gru_povrsina_parc,
    gru_ko: row.gru_ko,
  }));

  const toggleDrawer = () => {
    setVisibleDrawer(!visibleDrawer);
  };

  const columns = [
    {
      title: 'Prijavni list',
      dataIndex: 'broj',
      key: 'broj',
      className: 'broj',
      width: 100,
      align: 'center',
      sorter: (a, b) => a.broj - b.broj,
    },
    {
      title: 'BROJ PARCELE',
      align: 'center',
      className: 'group-kat-gru',
      children: [
        {
          title: 'Kat',
          dataIndex: 'broj_parcele_kop',
          key: 'broj_parcele_kop',
          className: 'parcela',
          align: 'center',
          sorter: (a, b) =>
            a.broj_parcele_kop.localeCompare(b.broj_parcele_kop, undefined, {
              numeric: true,
              sensitivity: 'base',
            }),
        },
        {
          title: 'Gru',
          dataIndex: 'broj_parcele_grunt',
          key: 'broj_parcele_grunt',
          className: 'parcela',
          align: 'center',
          sorter: (a, b) =>
            a.broj_parcele_grunt.localeCompare(
              b.broj_parcele_grunt,
              undefined,
              {
                numeric: true,
                sensitivity: 'base',
              }
            ),
        },
      ],
    },
    {
      title: 'POVRŠINA',
      align: 'center',
      className: 'group-kat-gru',
      children: [
        {
          title: 'Kat',
          dataIndex: 'kat_povrsina_parc',
          key: 'kat_povrsina_parc',
          className: 'povrsina',
          align: 'center',
          sorter: (a, b) => a.kat_povrsina_parc - b.kat_povrsina_parc,
        },
        {
          title: 'Gru',
          dataIndex: 'gru_povrsina_parc',
          key: 'gru_povrsina_parc',
          className: 'povrsina',
          align: 'center',
          sorter: (a, b) => a.gru_povrsina_parc - b.gru_povrsina_parc,
        },
      ],
    },
    {
      title: 'KO STARI PREMJER',
      dataIndex: 'gru_ko',
      key: 'gru_ko',
      align: 'center',
    },
  ];

  // * Pagination length for total & show total
  let all = dataDrawer.length;
  const showTotal = (total) => `Total ${total}`;

  return (
    <div className='container-fluid pregled site-drawer-render-in-current-wrapper'>
      <Drawer
        title='Prikaz kontrole identifikovanih parcela kojih nema u katastarskim podacima'
        placement='right'
        width='700'
        onClose={toggleDrawer}
        visible={visibleDrawer}
        style={{ top: 68 }}>
        <p>
          Prikazane parcele su dobivene upoređivanjem ukupnog broja unosa
          identifikacije i ukupnog broja parcela iz katastarskog operata.
        </p>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            columns={columns}
            dataSource={dataDrawer}
            locale={locale}
            sticky
            bordered
            className='difference-table'
            size='small'
            scroll={{ y: 'calc(100vh - 19.8rem)' }}
            pagination={{
              hideOnSinglePage: true,
              locale: { ...locale },
              position: ['bottomRight'],
              defaultPageSize: 10,
              pageSizeOptions: [10, 20, 50, all],
              showTotal,
            }}
          />
        </ConfigProvider>
      </Drawer>
      <div className='row gx-4'>
        <div className='col-6'>
          <div className='bg-danger text-white text-center rounded-top er-bold py-1 px-2'>
            PREGLED SVIH PARCELA KATASTRA - K.O. {currentRadilisteNovi}
          </div>
          <TableAListPregled dataTable={dataKatA} ident={identKat} spnp='np' />
        </div>
        <div className='col-6'>
          <div className='bg-info text-white text-center rounded-top er-bold py-1 px-2'>
            PREGLED SVIH PARCELA GRUNTA - K.O. {currentRadilisteStari}
          </div>
          <TableAListPregled dataTable={dataGruA} ident={identGru} spnp='sp' />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  identKat: state.pls.identKat,
  identGru: state.pls.identGru,
  currentRadilisteStari: state.radiliste.currentRadilisteStari,
  currentRadilisteNovi: state.radiliste.currentRadilisteNovi,
  visibleDrawer: state.radiliste.visibleDrawer,
});

const mapDispatchToProps = (dispatch) => ({
  setVisibleDrawer: (prikazi) => dispatch(setVisibleDrawer(prikazi)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllParcele);
