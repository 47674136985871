import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";

import { auth } from "../../firebase/firebase.utils";

import "./sign-in.styles.scss";

const SignIn = () => {
  const navigate = useNavigate();

  const [cred, setCred] = useState({
      email: "",
      password: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { email, password } = cred;

    try {
      await auth.signInWithEmailAndPassword(email, password);
      setCred({ email: "", password: "" });
      navigate('/');
    } catch (error) {
      console.log(
        '%c ⛔ error: ',
        'font-size:20px;background-color: #465975;color:#fff;',
        error
      );
    }
  };

  const handleChange = (event) => {
    setCred({
      ...cred,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className="sign-in">
      <h2 className="title">Već imam račun</h2>
      <span>Prijavite se sa e-mailom i lozinkom</span>

      <form onSubmit={handleSubmit}>
        <FormInput
          name="email"
          type="email"
          handleChange={handleChange}
          value={cred.email}
          label="email"
          required
        />
        <FormInput
          name="password"
          type="password"
          value={cred.password}
          handleChange={handleChange}
          label="lozinka"
          required
        />
        <div className="buttons">
          <CustomButton type="submit">Prijava</CustomButton>
          {/* <CustomButton
            type="button"
            onClick={signInWithGoogle}
            isGoogleSignIn
          >
            Prijava sa Googlom
          </CustomButton> */}
        </div>
      </form>
    </div>
  );
}

export default SignIn;
