import { PlsActionsTypes } from './pls.types';

export const setActivePls = (pls) => ({
  type: PlsActionsTypes.SET_ACTIVE_PLS,
  payload: pls,
});

export const setCurrentPls = (pls) => ({
  type: PlsActionsTypes.SET_CURRENT_PLS,
  payload: pls,
});

export const setCurrentPlsOd = (pls) => ({
  type: PlsActionsTypes.SET_CURRENT_PLSOD,
  payload: pls,
});

export const setCurrentPlsDo = (pls) => ({
  type: PlsActionsTypes.SET_CURRENT_PLSDO,
  payload: pls,
});

export const setLastPL = (pls) => ({
  type: PlsActionsTypes.ADD_LAST_PL,
  payload: pls,
});

export const setIdentKat = (pls) => ({
  type: PlsActionsTypes.SET_IDENT_KAT,
  payload: pls,
});

export const setIdentGru = (pls) => ({
  type: PlsActionsTypes.SET_IDENT_GRU,
  payload: pls,
});

export const setStatusKat = (pls) => ({
  type: PlsActionsTypes.SET_STATUS_KAT,
  payload: pls,
});

export const setStatusGru = (pls) => ({
  type: PlsActionsTypes.SET_STATUS_GRU,
  payload: pls,
});
