import React from 'react';
import { Table, Tag, Tooltip, ConfigProvider } from 'antd';
import 'antd/dist/antd.min.css';

import { locale } from '../../locale/bs_BA';
import { customizeRenderEmpty } from '../../util/customizeEmpty';

import TableActionButton from '../../components/table-action-button/table-action-button.components';

import './antd-table-prijavni-list.styles.scss';

const TablePrijavniList = ({ dataTable, active }) => {
  // * Table Columns definitions
  const columns = [
    {
      title: 'Prijavni list',
      dataIndex: 'broj',
      key: 'broj',
      className: 'broj',
      width: 66,
      align: 'center',
      sorter: (a, b) => a.broj - b.broj,
    },
    {
      title: <span className="vertical">Vrsta</span>,
      dataIndex: 'vrsta',
      key: 'vrsta',
      className: 'vrsta',
      width: 30,
      align: 'center',
      sorter: (a, b) =>
        a.vrsta.localeCompare(b.vrsta, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      render: (vrs) => {
        let color;
        if (vrs === 'Čl. 88, stav 1 ZZK') {
          color = 'blue';
        }
        if (vrs === 'Čl. 88, stav 2 ZZK') {
          color = 'cyan';
        }
        if (vrs === 'Čl. 73, stav 1 ZZK') {
          color = 'purple';
        }
        if (vrs === 'Čl. 63-67 ZZK') {
          color = 'volcano';
        }
        return (
          <Tooltip placement="right" title={vrs} color={color} key={vrs}>
            <Tag color={color} key={vrs}>
              {vrs === 'Čl. 88, stav 1 ZZK' && <span>1</span>}
              {vrs === 'Čl. 88, stav 2 ZZK' && <span>2</span>}
              {vrs === 'Čl. 73, stav 1 ZZK' && <span>3</span>}
              {vrs === 'Čl. 63-67 ZZK' && <span>4</span>}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: 'KATASTAR PARCELE',
      align: 'center',
      className: 'group-kat-gru',
      children: [
        {
          title: 'PL',
          dataIndex: 'kat_posjednik',
          key: 'kat_posjednik',
          className: 'pl-zk',
          width: 50,
          align: 'center',
          sorter: (a, b) => a.kat_posjednik - b.kat_posjednik,
        },
        {
          title: 'Broj',
          dataIndex: 'broj_parcele_kop',
          key: 'broj_parcele_kop',
          className: 'parcela',
          align: 'center',
          sorter: (a, b) =>
            a.broj_parcele_kop.localeCompare(b.broj_parcele_kop, undefined, {
              numeric: true,
              sensitivity: 'base',
            }),
        },
        {
          title: 'Površina',
          dataIndex: 'kat_povrsina_parc',
          key: 'kat_povrsina_parc',
          className: 'povrsina',
          align: 'center',
          sorter: (a, b) => a.kat_povrsina_parc - b.kat_povrsina_parc,
        },
      ],
    },
    {
      title: 'GRUNT PARCELE',
      align: 'center',
      className: 'group-kat-gru',
      children: [
        {
          title: 'ZK',
          dataIndex: 'gru_vlasnik',
          key: 'gru_vlasnik',
          className: 'pl-zk',
          width: 50,
          align: 'center',
          sorter: (a, b) =>
            a.gru_vlasnik.localeCompare(b.gru_vlasnik, undefined, {
              numeric: true,
              sensitivity: 'base',
            }),
        },
        {
          title: 'Broj',
          dataIndex: 'broj_parcele_grunt',
          key: 'broj_parcele_grunt',
          className: 'parcela',
          align: 'center',
          sorter: (a, b) =>
            a.broj_parcele_grunt.localeCompare(b.broj_parcele_grunt, undefined, {
              numeric: true,
              sensitivity: 'base',
            }),
        },
        {
          title: 'Površina',
          dataIndex: 'gru_povrsina_parc',
          key: 'gru_povrsina_parc',
          className: 'povrsina',
          align: 'center',
          sorter: (a, b) => a.gru_povrsina_parc - b.gru_povrsina_parc,
        },
      ],
    },
    {
      title: 'Razlika površina',
      dataIndex: 'razlika_povrsina',
      key: 'razlika_povrsina',
      className: 'razlika-povrsina',
      width: 74,
      align: 'center',
      sorter: (a, b) => a.razlika_povrsina - b.razlika_povrsina,
    },
    {
      title: <span className="vertical">Akcije</span>,
      dataIndex: 'id',
      key: 'id',
      className: 'akcije',
      width: 33,
      align: 'center',
      render: (r, index) => <TableActionButton itemId={index.id} />,
    },
  ];

  // * Pagination length for total & show total
  let all = dataTable.length;
  const showTotal = (total) => `Total ${total}`;

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table
        columns={columns}
        dataSource={dataTable}
        locale={locale}
        sticky
        bordered
        className="prijavni-list-table"
        rowClassName={(record, index) => (record.broj === active ? 'active' : '')}
        size="small"
        scroll={{ y: 'calc(100vh - 19.8rem)' }}
        pagination={{
          locale: { ...locale },
          position: ['bottomCenter'],
          defaultPageSize: 100,
          pageSizeOptions: [10, 20, 50, 100, 200, all],
          showQuickJumper: true,
          showTotal,
        }}
      />
    </ConfigProvider>
  );
};

export default TablePrijavniList;
