import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import './App.scss';

import HomePage from './pages/homepage/homepage.component';
import Unos from './pages/unos/unos.component';
import Pregled from './pages/pregled/pregled.component';
import PrijavniList from './pages/prijavnilist/prijavnilist.component';
import PrijavniListMultiSp from './pages/prijavnilistmultisp/prijavnilistmultisp.component';
import PrintExport from './pages/printexport/printexport.component';
import PdfPrijavniList from './pages/pdf/pdf.component';
import PdfMultiSpPrijavniList from './pages/pdfmultisp/pdfmultisp.component';

import SignInAndSignUpPage from './pages/sign-in-and-sign-up/sign-in-and-sign-up.component';

import Header from './components/header/header.component';

import { auth, createUserProfileDocument } from './firebase/firebase.utils';

import { setCurrentUser } from './redux/user/user.actions';
import { selectCurrentUser } from './redux/user/user.selectors';

const App = ({ currentUser, setCurrentUser }) => {
  useEffect(() => {
    let unsubscribeFromAuth = null;

    unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);

        userRef.onSnapshot((snapShot) => {
          setCurrentUser({
            id: snapShot.id,
            ...snapShot.data(),
          });
        });
      }

      setCurrentUser(userAuth);
    });

    // CleanUp
    return () => {
      unsubscribeFromAuth();
    };
  }, [setCurrentUser]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/pdf/*" element={<PdfPrijavniList />} />
        <Route path="/multisp/*" element={<PdfMultiSpPrijavniList />} />
        <Route path="/unos" element={<Unos />} render={() => (currentUser ? <Unos /> : <Navigate replace to="/" />)} />
        <Route
          path="/pregled"
          element={<Pregled />}
          render={() => (currentUser ? <Pregled /> : <Navigate replace to="/" />)}
        />
        <Route
          path="/prijavni-listovi"
          element={<PrijavniList />}
          render={() => (currentUser ? <PrijavniList /> : <Navigate replace to="/" />)}
        />
        <Route
          path="/prijavni-listovi-msp"
          element={<PrijavniListMultiSp />}
          render={() => (currentUser ? <PrijavniListMultiSp /> : <Navigate replace to="/" />)}
        />
        <Route
          path="/print-export"
          element={<PrintExport />}
          render={() => (currentUser ? <PrintExport /> : <Navigate replace to="/" />)}
        />
        <Route
          path="/signin"
          element={<SignInAndSignUpPage />}
          render={() => (currentUser ? <Navigate replace to="/" /> : <SignInAndSignUpPage />)}
        />
      </Routes>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
