import { RadilisteActionsTypes } from './radiliste.types';

export const setCurrentRadilisteStari = (radiliste) => ({
  type: RadilisteActionsTypes.SET_CURRENT_RADILISTE_STARI,
  payload: radiliste,
});

export const setCurrentRadilisteNovi = (radiliste) => ({
  type: RadilisteActionsTypes.SET_CURRENT_RADILISTE_NOVI,
  payload: radiliste,
});

export const setVisibleDrawer = (radiliste) => ({
  type: RadilisteActionsTypes.SET_VISIBLE_DRAWER,
  payload: radiliste,
});
