import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';

import {
  setCurrentKoppl,
  setUpdateKoppl,
} from '../../redux/koppl/koppl.actions';
import {
  setCurrentGruzk,
  setUpdateGruzk,
} from '../../redux/gruzk/gruzk.actions';
import {
  setLastPL,
  setIdentKat,
  setIdentGru,
  setStatusKat,
  setStatusGru,
} from '../../redux/pls/pls.actions';
import {
  setParcelaKatastar,
  setParcelaGrunt,
  setOptionsGru,
  setOptionsKat,
} from '../../redux/parcele/parcele.actions';

import { ToastBottom } from '../../components/swal-modal-toast/swal-modal-toast.components';

import TableAList from '../../components/antd-table-a-list/antd-table-a-list.components';
import TableBList from '../../components/antd-table-b-list/antd-table-b-list.components';
import TableCList from '../../components/antd-table-c-list/antd-table-c-list.components';
import TablePrijavniList from '../../components/antd-table-prijavni-list/antd-table-prijavni-list.components';

import api from '../../util/api';
import config from '../../config/host';
import partialContains from '../../util/partialContains';
import customStyles from '../../util/selectCustomStyle';

import './unos.styles.scss';

const Pregled = ({
  pls,
  koppl,
  gruzk,
  activePls,
  setLastPL,
  setCurrentKoppl,
  setUpdateKoppl,
  setCurrentGruzk,
  setUpdateGruzk,
  currentRadilisteNovi,
  currentRadilisteStari,
  setParcelaGrunt,
  setParcelaKatastar,
  setIdentKat,
  setIdentGru,
  setStatusKat,
  setStatusGru,
  setOptionsGru,
  setOptionsKat,
}) => {
  const [optionsPl, setOptionsPl] = useState([]);
  const [optionsParc, setOptionsParc] = useState([]);
  const [optionsPosj, setOptionsPosj] = useState([]);

  const [optionsZk, setOptionsZk] = useState([]);
  const [optionsParcZk, setOptionsParcZk] = useState([]);
  const [optionsVlasnik, setOptionsVlasnik] = useState([]);

  // * Filtriranje rezultata PL - posjednik
  const filterResultsPLPosjednik = (s) => {
    setCurrentKoppl(s.value);
  };

  // * Filtriranje rezultata PL - broj parcele odvojio radi upisa u form input
  const filterResultsPLParcela = (s) => {
    // Provjera da li se nalazi parcela i novi broj prijavnog lista za unos
    // sa podacima u tabeli poredeci borj prijavnog lista i parcelu zajedno
    let izInputa = { broj: activePls, parc: s.label };
    const containing = pls.identKat.find((object) =>
      partialContains(object, izInputa)
    );
    const found = pls.identKat.find((x) => x.parc === s.label);
    // Slucaj kada treba pustiti odabir jer je u istom prijavnom listu
    if (containing) {
      ToastBottom.fire({
        icon: 'success',
        title: 'Parcela broj:',
        text: `${s.label} je odabrana!`,
      });
      setOptionsKat([{ value: s.label, label: s.label }]);
      setParcelaKatastar(s.label);
      setCurrentKoppl(s.value);
    }
    // Slucaj kada se odabrana parcela nalazi u nekom drugom prijavnom listu
    // uslovno zabranjen odabir jer korisnik moze reci da zeli nastaviti
    if (!containing) {
      if (found) {
        const identSwalK = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success me-3',
            cancelButton: 'btn btn-danger ms-3',
          },
          buttonsStyling: false,
        });
        identSwalK
          .fire({
            title: 'Upozorenje',
            html: `Parcela <span class="fw-bold">${found.parc}</span> je već identifikovana u PL <span class="fw-bold">${found.broj}</span>`,
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: `Nastavi`,
            cancelButtonText: `Odustani`,
            reverseButtons: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              identSwalK.fire({
                icon: 'success',
                title: 'Odabrano! ',
                text: 'Odabrali ste već identifikovanu parcelu.',
                showConfirmButton: false,
                timer: 1500,
              });
              setOptionsKat([{ value: s.label, label: s.label }]);
              setParcelaKatastar(s.label);
              setCurrentKoppl(s.value);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              ToastBottom.fire({
                icon: 'info',
                title: 'Otkazano! ',
                text: 'Stanje odabira nema promjena.',
              });
            }
          });
      } else {
        ToastBottom.fire({
          icon: 'success',
          title: 'Parcela broj: ',
          text: `${s.label} je odabrana!`,
        });
        setOptionsKat([{ value: s.label, label: s.label }]);
        setParcelaKatastar(s.label);
        setCurrentKoppl(s.value);
      }
    }
  };

  // * Filtriranje rezultata PL - broj pl
  const filterResultsPLBroj = (s) => {
    setCurrentKoppl(s.value);
  };

  // ! LIMIT
  const pretragaPosjednika = (event) => {
    var textLength = event.length;
    if (textLength) {
      try {
        api
          .get(
            `/table/kop_select_naziv/distinct/?limit=10000&where=naziv LIKE('__${event}__') and ko="${currentRadilisteNovi}"&order=naziv asc`
          )
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              const podaciPl = Array.from(
                new Set(result.map((s) => s.kop_pl))
              ).map((kop_pl) => {
                return {
                  value: kop_pl,
                  label:
                    result.find((s) => s.kop_pl === kop_pl).naziv +
                    '  (' +
                    kop_pl +
                    ')',
                };
              });
              setOptionsPosj(podaciPl);
            }
          });
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };

  // ! LIMIT
  const pretragaParcelaPL = (event) => {
    var textLength = event.length;
    if (textLength >= 1) {
      try {
        api
          .get(
            `/table/kop_select/distinct/?limit=99999&where=broj_parcele LIKE('__${event}__') and ko="${currentRadilisteNovi}"&order=CAST(IF(LEFT(broj_parcele, POSITION('/' in broj_parcele)) = 0, broj_parcele, LEFT(broj_parcele, POSITION('/' in broj_parcele)-1)) AS INTEGER), CAST(IF(LEFT(broj_parcele, POSITION('/' in broj_parcele)) = 0, 0, RIGHT(broj_parcele, (LENGTH(broj_parcele) - POSITION('/' in broj_parcele)))) AS INTEGER)`
          )
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              const podaciParcOptionsK = result.map((o) => {
                return {
                  value: o.broj_parcele,
                  label: o.broj_parcele,
                };
              });
              setOptionsKat(podaciParcOptionsK);

              const podaciParcPl = result.map((s) => {
                return {
                  value: s.kop_pl,
                  label: s.broj_parcele,
                };
              });
              setOptionsParc(podaciParcPl);
            }
          });
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };

  // ! LIMIT
  const pretragaPL = (event) => {
    var textLength = event.length;
    if (textLength >= 1) {
      try {
        api
          .get(
            `/table/kop_select/distinct/?limit=99999&where=kop_pl LIKE('__${event}__') and ko="${currentRadilisteNovi}"&order=kop_pl * 1, kop_pl`
          )
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              const podaciPl = Array.from(
                new Set(result.map((s) => s.kop_pl))
              ).map((kop_pl) => {
                return {
                  value: kop_pl,
                  label: result.find((s) => s.kop_pl === kop_pl).kop_pl,
                };
              });
              setOptionsPl(podaciPl);
            }
          });
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };

  // * Filtriranje rezultata ZK - broj parcele odvojio radi upisa u form input
  const filterResultsZKParcela = (s) => {
    // Provjera da li se nalazi parcela i novi broj prijavnog lista za unos
    // sa podacima u tabeli poredeci broj prijavnog lista i parcelu zajedno
    let izInputa = { broj: activePls, parc: s.label };
    const containing = pls.identGru.find((object) =>
      partialContains(object, izInputa)
    );
    const found = pls.identGru.find((x) => x.parc === s.label);
    // Slucaj kada treba pustiti odabir jer je u istom prijavnom listu
    if (containing) {
      ToastBottom.fire({
        icon: 'success',
        title: 'Parcela broj:',
        text: `${s.label} je odabrana!`,
      });
      setOptionsGru([{ value: s.label, label: s.label }]);
      setParcelaGrunt(s.label);
      setCurrentGruzk(s.value);
    }
    // Slucaj kada se odabrana parcela nalazi u nekom drugom prijavnom listu
    // uslovno zabranjen odabir jer korisnik moze reci da zeli nastaviti
    if (!containing) {
      if (found) {
        const identSwalK = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success me-3',
            cancelButton: 'btn btn-danger ms-3',
          },
          buttonsStyling: false,
        });
        identSwalK
          .fire({
            title: 'Upozorenje',
            html: `Parcela <span class="fw-bold">${found.parc}</span> je već identifikovana u PL <span class="fw-bold">${found.broj}</span>`,
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: `Nastavi`,
            cancelButtonText: `Odustani`,
            reverseButtons: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              identSwalK.fire({
                icon: 'success',
                title: 'Odabrano! ',
                text: 'Odabrali ste već identifikovanu parcelu.',
                showConfirmButton: false,
                timer: 1500,
              });
              setOptionsGru([{ value: s.label, label: s.label }]);
              setParcelaGrunt(s.label);
              setCurrentGruzk(s.value);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              ToastBottom.fire({
                icon: 'info',
                title: 'Otkazano! ',
                text: 'Stanje odabira nema promjena.',
              });
            }
          });
      } else {
        ToastBottom.fire({
          icon: 'success',
          title: 'Parcela broj: ',
          text: `${s.label} je odabrana!`,
        });
        setOptionsGru([{ value: s.label, label: s.label }]);
        setParcelaGrunt(s.label);
        setCurrentGruzk(s.value);
      }
    }
  };

  // * Filtriranje rezultata ZK
  const filterResultsZK = (value) => {
    setCurrentGruzk(value);
  };

  // ! LIMIT
  const pretragaVlasnika = (event) => {
    var textLength = event.length;
    if (textLength) {
      try {
        api
          .get(
            `/table/gru_select_naziv/distinct/?limit=10000&where=naziv LIKE('__${event}__') and ko="${currentRadilisteStari}"&order=naziv asc`
          )
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              const podaciZk = Array.from(
                new Set(result.map((s) => s.kop_pl))
              ).map((kop_pl) => {
                return {
                  value: kop_pl,
                  label:
                    result.find((s) => s.kop_pl === kop_pl).naziv +
                    '  (' +
                    kop_pl +
                    ')',
                };
              });
              setOptionsVlasnik(podaciZk);
            }
          });
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };

  // ! LIMIT
  const pretragaParcelaGrunt = (event) => {
    var textLength = event.length;
    if (textLength >= 1) {
      try {
        api
          .get(
            `/table/gru_select/distinct/?limit=99999&where=broj_parcele LIKE('__${event}__') and ko="${currentRadilisteStari}"&order=CAST(IF(LEFT(broj_parcele, POSITION('/' in broj_parcele)) = 0, broj_parcele, LEFT(broj_parcele, POSITION('/' in broj_parcele)-1)) AS INTEGER), CAST(IF(LEFT(broj_parcele, POSITION('/' in broj_parcele)) = 0, 0, RIGHT(broj_parcele, (LENGTH(broj_parcele) - POSITION('/' in broj_parcele)))) AS INTEGER)`
          )
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              const podaciParcOptionsG = result.map((o) => {
                return {
                  value: o.broj_parcele,
                  label: o.broj_parcele,
                };
              });
              setOptionsGru(podaciParcOptionsG);

              const podaciParcZk = result.map((s) => {
                return {
                  value: s.kop_pl,
                  label: s.broj_parcele,
                };
              });
              setOptionsParcZk(podaciParcZk);
            }
          });
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };

  // ! LIMIT
  const pretragaZK = (event) => {
    var textLength = event.length;
    if (textLength >= 1) {
      try {
        api
          .get(
            `/table/gru_select/distinct/?limit=99999&where=kop_pl LIKE('__${event}__') and ko="${currentRadilisteStari}"&order=kop_pl * 1, kop_pl`
          )
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              const podaciZk = Array.from(
                new Set(result.map((s) => s.kop_pl))
              ).map((kop_pl) => {
                return {
                  value: kop_pl,
                  label: result.find((s) => s.kop_pl === kop_pl).kop_pl,
                };
              });
              setOptionsZk(podaciZk);
            }
          });
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };

  // * GET podataka KOP parcela - to redux state
  useEffect(() => {
    if (koppl.currentKoppl || koppl.currentKoppl === 0) {
      try {
        api
          .get(
            `/table/kop_parc_dio/?where=kop_pl=${koppl.currentKoppl} and ko="${currentRadilisteNovi}"`
          )
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              setUpdateKoppl(result);
            } else {
              setUpdateKoppl([]);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [koppl.currentKoppl, currentRadilisteNovi, setUpdateKoppl]);

  // * GET podataka KOP posjednik
  const [dataKopPosjAPI, setDataKopPosjAPI] = useState([]);
  useEffect(() => {
    if (koppl.currentKoppl || koppl.currentKoppl === 0) {
      try {
        api
          .get(
            `/table/kop_b_list/?where=kop_pl=${koppl.currentKoppl} and ko="${currentRadilisteNovi}"`
          )
          .then((response) => {
            const result = response.data.data;
            if (result === 'No data.') {
              setDataKopPosjAPI([]);
            } else {
              setDataKopPosjAPI(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [koppl.currentKoppl, currentRadilisteNovi]);

  // * GET podataka Statistika KAT i GRU - to redux state
  useEffect(() => {
    // Ne dirati redoslijed od njega zavisi prikaz
    let one = `${config.API_HOST}table/prijavni_list/totalSize?where=kat_ko="${currentRadilisteNovi}"`;
    let two = `${config.API_HOST}table/prijavni_list_statistika_kat?where=ko="${currentRadilisteNovi}"`;
    let tree = `${config.API_HOST}table/prijavni_list/totalSize?where=gru_ko="${currentRadilisteStari}"`;
    let four = `${config.API_HOST}table/prijavni_list_statistika_gru?where=ko="${currentRadilisteStari}"`;
    let five = `${config.API_HOST}table/prijavni_list_ident_gru/?limit=10000&columns=broj, parc&where=ko="${currentRadilisteNovi}"`;
    let six = `${config.API_HOST}table/gru_a_list/totalSize?where=ko="${currentRadilisteStari}"`;
    let seven = `${config.API_HOST}table/kop_a_list/totalSize?where=ko="${currentRadilisteNovi}"`;

    const reqOne = axios.get(one);
    const reqTwo = axios.get(two);
    const reqTree = axios.get(tree);
    const reqFour = axios.get(four);
    const reqFive = axios.get(five);
    const reqSix = axios.get(six);
    const reqSeven = axios.get(seven);
    try {
      axios
        .all([reqOne, reqTwo, reqTree, reqFour, reqFive, reqSix, reqSeven])
        .then(
          axios.spread((...response) => {
            const resultKat1 = response[0].data;
            const resultKat2 = response[1].data.data[0];
            const resultGru1 = response[2].data;
            const resultGru2 = response[3].data.data[0];
            const resultGru3 = response[4].data.data.length;
            const resultGru4 = response[5].data;
            const resultKat3 = response[6].data;

            const joinKat = [resultKat1, resultKat2, resultKat3];
            const joinGru = [resultGru1, resultGru2, resultGru3, resultGru4];
            if (
              resultKat1 !== 'No data.' &&
              resultKat1 &&
              resultKat2 !== 'No data.' &&
              resultKat2 &&
              resultKat3 !== 'No data.' &&
              resultKat3
            ) {
              setStatusKat(joinKat);
            }
            if (
              resultGru1 !== 'No data.' &&
              resultGru1 &&
              resultGru2 !== 'No data.' &&
              resultGru2 &&
              resultGru3 !== 'No data.' &&
              resultGru3 &&
              resultGru4 !== 'No data.' &&
              resultGru4
            ) {
              setStatusGru(joinGru);
            }
          })
        )
        .catch((error) => {
          console.log('error: ', error);
        });
    } catch (err) {
      console.log('error: ', err);
    }
  }, [currentRadilisteNovi, currentRadilisteStari, setStatusKat, setStatusGru]);

  // * GET podataka GRU parcele - to redux state
  useEffect(() => {
    if (gruzk.currentGruzk || gruzk.currentGruzk === 0) {
      try {
        api
          .get(
            `/table/gru_parc_dio/?where=kop_pl=${gruzk.currentGruzk} and ko="${currentRadilisteStari}"`
          )
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              setUpdateGruzk(result);
            } else {
              setUpdateGruzk([]);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [gruzk.currentGruzk, currentRadilisteStari, setUpdateGruzk]);

  // * GET podataka GRU vlasnici
  const [dataGruVlasAPI, setDataGruVlasAPI] = useState([]);
  useEffect(() => {
    if (gruzk.currentGruzk || gruzk.currentGruzk === 0) {
      try {
        api
          .get(
            `/table/gru_b_list/?where=kop_pl=${gruzk.currentGruzk} and ko="${currentRadilisteStari}"`
          )
          .then((response) => {
            const result = response.data.data;
            if (result === 'No data.') {
              setDataGruVlasAPI([]);
            } else {
              setDataGruVlasAPI(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [gruzk.currentGruzk, currentRadilisteStari]);

  // * GET podataka GRU tereti
  const [dataGruTeretAPI, setDataGruTeretAPI] = useState([]);
  useEffect(() => {
    if (gruzk.currentGruzk || gruzk.currentGruzk === 0) {
      try {
        api
          .get(
            `/table/gru_c_list/?where=kop_pl=${gruzk.currentGruzk} and ko="${currentRadilisteStari}"`
          )
          .then((response) => {
            const result = response.data.data;
            if (result === 'No data.') {
              setDataGruTeretAPI([]);
            } else {
              setDataGruTeretAPI(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [gruzk.currentGruzk, currentRadilisteStari]);

  // ! LIMIT
  // * GET podataka Prijavni List - to redux state
  useEffect(() => {
    try {
      api
        .get(
          `/table/prijavni_list/?limit=99999&where=kat_ko="${currentRadilisteNovi}" and gru_ko="${currentRadilisteStari}"`
        )
        .then((response) => {
          const result = response.data.data;
          if (result !== 'No data.' && result) {
            setLastPL(result);
          } else {
            setLastPL([]);
          }
        });
    } catch (err) {
      console.log('error: ', err);
    }
  }, [setLastPL, currentRadilisteNovi, currentRadilisteStari]);

  // ! LIMIT
  // * GET podataka Identifikacija KAT - to redux state
  useEffect(() => {
    try {
      api
        .get(
          `/table/prijavni_list_ident_kat/?limit=10000&columns=broj, parc&where=ko="${currentRadilisteNovi}"`
        )
        .then((response) => {
          const result = response.data.data;
          // result !== 'No data.' && result
          if (result) {
            setIdentKat(result);
          }
        });
    } catch (err) {
      console.log('error: ', err);
    }
  }, [currentRadilisteNovi, setIdentKat]);

  // ! LIMIT
  // * GET podataka Identifikacija GRU - to redux state
  useEffect(() => {
    try {
      api
        .get(
          `/table/prijavni_list_ident_gru/?limit=10000&columns=broj, parc&where=ko="${currentRadilisteNovi}" and gko="${currentRadilisteStari}"`
        )
        .then((response) => {
          const result = response.data.data;
          // result !== 'No data.' && result
          if (result) {
            setIdentGru(result);
          }
        });
    } catch (err) {
      console.log('error: ', err);
    }
  }, [currentRadilisteNovi, currentRadilisteStari, setIdentGru]);

  // * Priprema podataka za antd-table-prijavni-list
  const dataPl = pls.lastPL?.map((row, i) => ({
    key: i,
    id: row.id,
    broj: row.broj,
    vrsta: row.vrsta,
    kat_posjednik: row.kat_posjednik,
    broj_parcele_kop: row.broj_parcele_kop,
    kat_povrsina_parc: row.kat_povrsina_parc,
    gru_vlasnik: row.gru_vlasnik,
    broj_parcele_grunt: row.broj_parcele_grunt,
    gru_povrsina_parc: row.gru_povrsina_parc,
    razlika_povrsina: row.razlika_povrsina,
  }));

  // * Priprema podataka za antd-table-a-list KOP
  const dataKatA = koppl.collectionsKoppl?.map((row, i) => ({
    key: i,
    broj_parcele: row.broj_parcele,
    naziv_parcele: row.naziv_parcele,
    povrsina_parcele: row.povrsina_parcele,
    nacin_koristenja: row.nacin_koristenja,
    povrsina_dijela_parcele: row.povrsina_dijela_parcele,
    // tags: pls.identKat?.includes(row.broj_parcele) ? 1 : 0, // Sporo uzas
    tags: pls.identKat.find((x) => x.parc === row.broj_parcele) ? 1 : 0,
  }));

  // * Priprema podataka za antd-table-b-list KOP
  const dataKatB = dataKopPosjAPI?.map((row, i) => ({
    key: i,
    naziv: row.naziv,
    adresa: row.adresa,
    udio: row.udio,
  }));

  // * Priprema podataka za antd-table-a-list GRU
  const dataGruA = gruzk.collectionsGruzk?.map((row, i) => ({
    key: i,
    broj_parcele: row.broj_parcele,
    naziv_parcele: row.naziv_parcele,
    povrsina_parcele: row.povrsina_parcele,
    nacin_koristenja: row.nacin_koristenja,
    povrsina_dijela_parcele: row.povrsina_dijela_parcele,
    // tags: pls.identGru?.includes(row.broj_parcele) ? 1 : 0, // Sporo uzas
    tags: pls.identGru.find((x) => x.parc === row.broj_parcele) ? 1 : 0,
  }));

  // * Priprema podataka za antd-table-b-list GRU
  const dataGruB = dataGruVlasAPI?.map((row, i) => ({
    key: i,
    naziv: row.naziv,
    adresa: row.adresa,
    udio: row.udio,
  }));

  // * Priprema podataka za antd-table-c-list GRU
  const dataGruC = dataGruTeretAPI?.map((row, i) => ({
    key: i,
    redbr: row.rb,
    opis: row.opis,
    iznos: row.iznos,
    primjedba: row.primjedba,
  }));

  return (
    <div className='container-fluid unos'>
      <div className='row gx-4'>
        <div className='col-4'>
          <div className='bg-secondary text-white rounded-top er-bold px-2 pt-1'>
            <div className='row gx-1'>
              <div className='col-12 d-flex justify-content-between'>
                <div className='text-start'>IDENTIFIKACIJA</div>
                <div className='text-end'>
                  <span className='me-2'>{currentRadilisteNovi}</span>
                  <span> - </span>
                  <span className='ms-2'>{currentRadilisteStari}</span>
                </div>
              </div>
              <div className='col-12 mb-1 statistika'>
                <div className='p-0 d-flex justify-content-between'>
                  <div className='statistika-left text-start'>
                    <div className='small'>
                      <span className='small text-white text-normal me-1'>
                        {currentRadilisteStari}
                      </span>
                      <span className='small text-dark text-normal me-1'>
                        prijavni listovi
                      </span>
                      <span className='text-cyan me-1'>
                        {pls.statusGru.length > 0 ? pls.statusGru[1].broj : 0}
                      </span>
                    </div>
                    <div className='small mt-0'>
                      <span className='small text-white text-normal me-1'>
                        {currentRadilisteStari}
                      </span>
                      <span className='small text-dark text-normal me-1'>
                        ukupno unosa
                      </span>
                      <span className='text-cyan text-end  me-1'>
                        {pls.statusGru.length > 0
                          ? pls.statusGru[0].totalSize
                          : 0}
                      </span>
                    </div>
                  </div>
                  <div className='statistika-centar text-left'>
                    <div className='small'>
                      <span className='small text-dark text-normal'>
                        Ukupno prijavnih listova
                      </span>
                      <span className='text-cyan ms-2'>
                        {pls.statusKat.length > 0 ? pls.statusKat[1].broj : 0}
                      </span>
                    </div>
                    <div className='small'>
                      <span className='small text-dark text-normal'>
                        Ukupno unosa
                      </span>
                      <span className='text-cyan ms-2'>
                        {pls.statusKat.length > 0
                          ? pls.statusKat[0].totalSize
                          : 0}
                      </span>
                    </div>
                  </div>
                  <div className='statistika-right text-end'>
                    <div className='small'>
                      <span className='small text-dark text-normal me-1'>
                        Katastar identifikacija
                      </span>
                      <span className='text-cyan me-1'>
                        {pls.identKat.length > 0 ? pls.identKat.length : 0}
                      </span>
                      <span className='small text-dark text-normal me-1'>
                        od
                      </span>
                      <span className='text-cyan me-1'>
                        {pls.statusKat.length > 2
                          ? pls.statusKat[2].totalSize
                          : 0}
                      </span>
                    </div>
                    <div className='small'>
                      <span className='small text-dark text-normal me-1'>
                        Grunt identifikacija
                      </span>
                      <span className='text-cyan me-1'>
                        {pls.statusGru.length > 0 ? pls.statusGru[2] : 0}
                        <span className='small text-dark text-normal'> -</span>
                      </span>
                      <span className='small text-dark text-normal me-1'>
                        ovaj SP
                      </span>
                      <span className='text-cyan me-1'>
                        {pls.identGru.length > 0 ? pls.identGru.length : 0}
                      </span>
                      <span className='small text-dark text-normal me-1'>
                        od
                      </span>
                      <span className='text-cyan me-1'>
                        {pls.statusGru.length > 3
                          ? pls.statusGru[3].totalSize
                          : 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TablePrijavniList dataTable={dataPl} active={activePls} />
        </div>

        <div className='col-4'>
          <div className='bg-danger text-white text-center rounded px-2 py-1 mb-3'>
            <div className='row gx-1'>
              <div className='text-start er-bold'>POSJEDOVNI LIST</div>
              <div className='col-12'>
                <div className='row gx-1 d-flex justify-content-between'>
                  <div className='col-6'>
                    <Select
                      className='select-naziv-custom'
                      placeholder='Pretraga posjednika'
                      styles={customStyles}
                      width='298px'
                      menuColor='#333'
                      noOptionsMessage={() => 'Počnite kucati za pretragu'}
                      options={optionsPosj}
                      onInputChange={(s) => pretragaPosjednika(s)}
                      onChange={(s) => filterResultsPLPosjednik(s)}
                    />
                  </div>
                  <div className='col-3'>
                    <Select
                      className='select-broj-custom'
                      placeholder='Broj parcele'
                      styles={customStyles}
                      width='146px'
                      menuColor='#333'
                      noOptionsMessage={() => 'Unesite broj za pretragu'}
                      options={optionsParc}
                      onInputChange={pretragaParcelaPL}
                      onChange={filterResultsPLParcela}
                    />
                  </div>
                  <div className='col-3'>
                    <Select
                      placeholder='Broj PL-a'
                      styles={customStyles}
                      width='146px'
                      menuColor='#333'
                      noOptionsMessage={() => 'Unesite broj za pretragu'}
                      options={optionsPl}
                      onInputChange={(s) => pretragaPL(s)}
                      onChange={(s) => filterResultsPLBroj(s)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='bg-danger text-white text-center rounded-top er-bold py-1 px-2'>
            "A LIST" - PREGLED PARCELA{' '}
            <div className='float-end'>Odabrani PL - {koppl.currentKoppl}</div>
          </div>
          <TableAList
            dataTable={dataKatA}
            ident={pls.identKat}
            spnp='np'
            scroll={{ y: 'calc(100vh - 19.8rem)' }}
          />

          <div className='bg-danger text-white text-center rounded-top er-bold py-1'>
            "B LIST" - PREGLED POSJEDNIKA
          </div>
          <TableBList dataTable={dataKatB} />
        </div>

        <div className='col-4 mb-5'>
          <div className='bg-info text-white text-center rounded px-2 py-1 mb-3'>
            <div className='row gx-1'>
              <div className='text-start er-bold'>ZEMLJIŠNO-KNJIŽNI ULOŽAK</div>
              <div className='col-12'>
                <div className='row gx-1 d-flex justify-content-between'>
                  <div className='col-6'>
                    <Select
                      className='select-naziv-custom'
                      placeholder='Pretraga vlasnika'
                      styles={customStyles}
                      width='298px'
                      menuColor='#333'
                      noOptionsMessage={() => 'Počnite kucati za pretragu'}
                      options={optionsVlasnik}
                      onInputChange={(s) => pretragaVlasnika(s)}
                      onChange={(s) => filterResultsZK(s.value)}
                    />
                  </div>
                  <div className='col-3'>
                    <Select
                      className='select-broj-custom'
                      placeholder='Broj parcele'
                      styles={customStyles}
                      width='146px'
                      menuColor='#333'
                      noOptionsMessage={() => 'Unesite broj za pretragu'}
                      options={optionsParcZk}
                      onInputChange={pretragaParcelaGrunt}
                      onChange={filterResultsZKParcela}
                    />
                  </div>
                  <div className='col-3'>
                    <Select
                      placeholder='Broj ZK'
                      styles={customStyles}
                      width='146px'
                      menuColor='#333'
                      noOptionsMessage={() => 'Unesite broj za pretragu'}
                      options={optionsZk}
                      onInputChange={(s) => pretragaZK(s)}
                      onChange={(s) => filterResultsZK(s.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='bg-info text-white text-center rounded-top er-bold py-1 px-2'>
            "A LIST" - PREGLED PARCELA
            <div className='float-end'>Odabrani ZK - {gruzk.currentGruzk}</div>
          </div>
          <TableAList
            dataTable={dataGruA}
            ident={pls.identGru}
            spnp='sp'
            scroll={{ y: 'calc(100vh - 19.8rem)' }}
          />

          <div className='bg-info text-white text-center rounded-top er-bold py-1'>
            "B LIST" - PREGLED VLASNIKA
          </div>
          <TableBList dataTable={dataGruB} />

          <div className='bg-info text-white text-center rounded-top er-bold py-1'>
            "C LIST" - TERETI
          </div>
          <TableCList dataTable={dataGruC} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  parcele: state.parcele,
  koppl: state.koppl,
  gruzk: state.gruzk,
  pls: state.pls,
  activePls: state.pls.activePls,
  lastPL: state.lastPL,
  currentKoppl: state.koppl.currentKoppl,
  currentGruzk: state.gruzk.currentGruzk,
  updateKoppl: state.koppl.collectionsKoppl,
  updateGruzk: state.gruzk.collectionsGruzk,
  currentRadilisteStari: state.radiliste.currentRadilisteStari,
  currentRadilisteNovi: state.radiliste.currentRadilisteNovi,
  optionsGru: state.parcele.parcelaGru,
  optionsKat: state.parcele.parcelaKat,
  parcelaKatastar: state.parcele.parcelaKatastar,
  parcelaGrunt: state.parcele.parcelaGrunt,
  identKat: state.pls.identKat,
  identGru: state.pls.identGru,
  statusKat: state.pls.statusKat,
  statusGru: state.pls.statusGru,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentKoppl: (kopplitem) => dispatch(setCurrentKoppl(kopplitem)),
  setCurrentGruzk: (gruzkitem) => dispatch(setCurrentGruzk(gruzkitem)),
  setUpdateKoppl: (kopplupdate) => dispatch(setUpdateKoppl(kopplupdate)),
  setUpdateGruzk: (gruzkupdate) => dispatch(setUpdateGruzk(gruzkupdate)),
  setOptionsGru: (optionG) => dispatch(setOptionsGru(optionG)),
  setOptionsKat: (optionK) => dispatch(setOptionsKat(optionK)),
  setParcelaKatastar: (parcelaK) => dispatch(setParcelaKatastar(parcelaK)),
  setParcelaGrunt: (parcelaG) => dispatch(setParcelaGrunt(parcelaG)),
  setLastPL: (pl) => dispatch(setLastPL(pl)),
  setIdentKat: (identkatupdate) => dispatch(setIdentKat(identkatupdate)),
  setIdentGru: (identgruupdate) => dispatch(setIdentGru(identgruupdate)),
  setStatusKat: (statuskatupdate) => dispatch(setStatusKat(statuskatupdate)),
  setStatusGru: (statusgruupdate) => dispatch(setStatusGru(statusgruupdate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pregled);
