import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import api from '../../util/api';
import CurrentYear from '../../util/currentYear';

import './prijavnilistmultisp.styles.scss';

const PrijavniList = ({ pls, currentRadilisteNovi, currentRadilisteStari }) => {
  const [zbirPovrsinaGru, setZbirPovrsinaGru] = useState([]);
  const [zbirPovrsinaKop, setZbirPovrsinaKop] = useState([]);

  // * GET podataka KOP parcela
  const [dataKopParcAPI, setDataKopParcAPI] = useState([]);
  useEffect(() => {
    if (pls.currentPls !== null && pls.currentPls > 0) {
      try {
        api
          .get(`/table/prijavni_list_kat_parc/?where=broj=${pls.currentPls} and ko="${currentRadilisteNovi}"`)
          .then((response) => {
            const result = response.data.data;
            if (result === 'No data.' || !result) {
              setDataKopParcAPI([]);
            } else {
              setDataKopParcAPI(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [pls.currentPls, currentRadilisteNovi]);

  // * GET podataka KOP posjednik
  const [dataKopPosjAPI, setDataKopPosjAPI] = useState([]);
  useEffect(() => {
    if (pls.currentPls !== null && pls.currentPls > 0) {
      try {
        api
          .get(`/table/prijavni_list_kat_vlas/?where=broj=${pls.currentPls} and ko="${currentRadilisteNovi}"`)
          .then((response) => {
            const result = response.data.data;
            if (result === 'No data.' || !result) {
              setDataKopPosjAPI([]);
            } else {
              setDataKopPosjAPI(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [pls.currentPls, currentRadilisteNovi]);

  // * Suma povrsina za KAT
  useEffect(() => {
    if (pls.currentPls !== null && pls.currentPls > 0) {
      try {
        api
          .get(`/table/suma_povrsina_kat/?where=broj=${pls.currentPls} and ko="${currentRadilisteNovi}"`)
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              setZbirPovrsinaKop(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
    return () => {};
  }, [pls.currentPls, currentRadilisteNovi]);

  // * GET podataka GRU parcele
  const [dataGruParcAPI, setDataGruParcAPI] = useState([]);
  useEffect(() => {
    if (pls.currentPls !== null && pls.currentPls > 0) {
      try {
        api
          .get(
            `/table/prijavni_list_gru_parc/?where=broj=${pls.currentPls} and kat_ko="${currentRadilisteNovi}"`, // ! ZAMJENA and ko="${currentRadilisteStari}" dodata kolona na bazi da se moze filtrirati po kat_ko
          )
          .then((response) => {
            const result = response.data.data;
            if (result === 'No data.' || !result) {
              setDataGruParcAPI([]);
            } else {
              setDataGruParcAPI(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [pls.currentPls, currentRadilisteNovi]);

  // * GET podataka GRU vlasnici
  const [dataGruVlasAPI, setDataGruVlasAPI] = useState([]);
  useEffect(() => {
    if (pls.currentPls !== null && pls.currentPls > 0) {
      try {
        api
          .get(
            `/table/prijavni_list_gru_vlas/?where=broj=${pls.currentPls} and kat_ko="${currentRadilisteNovi}"`, // ! ZAMJENA and ko="${currentRadilisteStari}" dodata kolona na bazi da se moze filtrirati po kat_ko
          )
          .then((response) => {
            const result = response.data.data;
            if (result === 'No data.' || !result) {
              setDataGruVlasAPI([]);
            } else {
              setDataGruVlasAPI(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [pls.currentPls, currentRadilisteNovi]);

  // * GET podataka GRU tereti
  const [dataGruTeretAPI, setDataGruTeretAPI] = useState([]);
  useEffect(() => {
    if (pls.currentPls !== null && pls.currentPls > 0) {
      try {
        api
          .get(
            `/table/prijavni_list_gru_teret/?where=broj=${pls.currentPls} and kat_ko="${currentRadilisteNovi}"`, // ! ZAMJENA and ko="${currentRadilisteStari}" dodata kolona na bazi da se moze filtrirati po kat_ko
          )
          .then((response) => {
            const result = response.data.data;
            if (result === 'No data.' || !result) {
              setDataGruTeretAPI([]);
            } else {
              setDataGruTeretAPI(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [pls.currentPls, currentRadilisteNovi]);

  // * Suma povrsina za GRU
  useEffect(() => {
    if (pls.currentPls !== null && pls.currentPls > 0) {
      try {
        api
          .get(
            `/table/suma_povrsina_gru/?where=broj=${pls.currentPls} and kat_ko="${currentRadilisteNovi}"`, // ! ZAMJENA and ko="${currentRadilisteStari}" dodata kolona na bazi da se moze filtrirati po kat_ko
          )
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              setZbirPovrsinaGru(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
    return () => {};
  }, [pls.currentPls, currentRadilisteNovi]);

  // * GRUNT Parcele loop kroz parcele za odabrani prijavni list ako se nalaze u vise ZK ulozaka
  let currentArrayGruP = [];
  let curentGruP = -1; // Krece od -1 radi 0 koje su moguce u podacima
  const loopKrozParceleGruntaSaIstimZk = () =>
    dataGruParcAPI.map((obj) => {
      if (curentGruP === obj.gru_pl) {
      } else {
        var localArrayGruP = dataGruParcAPI.filter((item) => obj.gru_pl === item.gru_pl);
        currentArrayGruP.push(localArrayGruP);
      }
      curentGruP = obj.gru_pl;
      return curentGruP;
    });
  loopKrozParceleGruntaSaIstimZk();

  // * GRUNT Vlasnici loop kroz vlasnike za odabrani prijavni list ako se nalaze u vise ZK ulozaka
  let currentArrayGruV = [];
  let curentGruV = -1; // Krece od -1 radi 0 koje su moguce u podacima
  const loopKrozVlasnikeGruntaSaIstimZk = () =>
    dataGruVlasAPI.map((obj) => {
      if (curentGruV === obj.gru_pl) {
      } else {
        var localArrayGruV = dataGruVlasAPI.filter((item) => obj.gru_pl === item.gru_pl);
        currentArrayGruV.push(localArrayGruV);
      }
      curentGruV = obj.gru_pl;
      return curentGruV;
    });
  loopKrozVlasnikeGruntaSaIstimZk();

  // * GRUNT Tereti loop kroz c list za odabrani prijavni list ako se nalaze u vise ZK ulozaka
  let currentArrayTeret = [];
  let curentTeret = -1; // Krece od -1 radi 0 koje su moguce u podacima
  const loopKrozTereteGruntaSaIstimZk = () =>
    dataGruTeretAPI?.map((obj) => {
      if (curentTeret === obj.gru_pl) {
      } else {
        var localArrayTeret = dataGruTeretAPI.filter((item) => obj.gru_pl === item.gru_pl);
        currentArrayTeret.push(localArrayTeret);
      }
      curentTeret = obj.gru_pl;
      return curentTeret;
    });
  loopKrozTereteGruntaSaIstimZk();

  // * KATASTAR Parcele loop kroz parcele za odabrani prijavni list ako se nalaze u vise Posjedovnih Listova
  let currentArrayKopP = [];
  let curentKopP = -1; // Krece od -1 radi 0 koje su moguce u podacima
  const loopKrozParceleKatastraSaIstimPl = () =>
    dataKopParcAPI.map((obj) => {
      if (curentKopP === obj.kop_pl) {
      } else {
        var localArrayKopP = dataKopParcAPI.filter((item) => obj.kop_pl === item.kop_pl);
        currentArrayKopP.push(localArrayKopP);
      }
      curentKopP = obj.kop_pl;
      return curentKopP;
    });
  loopKrozParceleKatastraSaIstimPl();

  // * KATASTAR Posjednici loop kroz posjednike za odabrani prijavni list ako se nalaze u vise Posjedovnih Listova
  let currentArrayKopV = [];
  let curentKopV = -1; // Krece od -1 radi 0 koje su moguce u podacima
  const loopKrozPosjednikeKatastraSaIstimPl = () =>
    dataKopPosjAPI.map((obj) => {
      if (curentKopV === obj.kop_pl) {
      } else {
        var localArrayKopV = dataKopPosjAPI.filter((item) => obj.kop_pl === item.kop_pl);
        currentArrayKopV.push(localArrayKopV);
      }
      curentKopV = obj.kop_pl;
      return curentKopV;
    });
  loopKrozPosjednikeKatastraSaIstimPl();

  // * GET tabele KOP_KO za zaglavlje Prijavni List
  const [podaciZaglavlje, setPodaciZaglavlje] = useState([]);
  const [podaciZaglavlje2, setPodaciZaglavlje2] = useState([]);
  const [podaciNalaz, setPodaciNalaz] = useState([]);
  useEffect(() => {
    if (pls.currentPls !== null && pls.currentPls > 0) {
      try {
        api.get(`/table/kop_ko?where=naziv="${currentRadilisteNovi}"`).then((response) => {
          const result = response.data.data;
          if (result !== 'No data.' && result) {
            setPodaciZaglavlje(...result);
          }
        });
      } catch (err) {
        console.log('error: ', err);
      }

      // * DIO koji uzimam iz lastPL redux state nalaz vrsta godina
      const found = pls.lastPL.find(({ broj }) => parseInt(broj) === parseInt(pls.currentPls));
      if (found) {
        const plNalazAll = pls.lastPL.filter((f) => parseInt(f.broj) === parseInt(pls.currentPls));
        const plNalazEmptyNotNull = plNalazAll.filter((n) => n.nalaz !== '');
        if (plNalazEmptyNotNull.length > 0) {
          setPodaciNalaz(plNalazEmptyNotNull[0].nalaz);
        } else {
          setPodaciNalaz([]);
        }
      }
      if (found) {
        const plPodaciVrstaGodina = pls.lastPL.filter((f) => parseInt(f.broj) === parseInt(pls.currentPls));
        setPodaciZaglavlje2(plPodaciVrstaGodina[0]);
      }
    }
  }, [pls.currentPls, pls.lastPL, currentRadilisteNovi]);

  // * SUM površina na PL i ZK parcelama za ukupne povrsine i razlike povrsina
  const [sumPovKopPl, setSumPovKopPl] = useState(0);
  const [sumPovGruZk, setSumPovGruZk] = useState(0);
  const [razlikePovrsina, setRazlikePovrsina] = useState(0);
  const [razlikePovrsinaProcenti, setRazlikePovrsinaProcenti] = useState(0);
  useEffect(() => {
    let sabiranjePl = dataKopParcAPI?.map((a_item) => a_item.povrsina_dijela_parcele);
    let sabiranjeZk = dataGruParcAPI?.map((a_item) => a_item.povrsina_dijela_parcele);
    if (sabiranjePl.length > 0) {
      setSumPovKopPl(sabiranjePl.reduce((a, v) => (a = a + v), 0));
    }
    if (sabiranjeZk.length > 0) {
      setSumPovGruZk(sabiranjeZk.reduce((a, v) => (a = a + v), 0));
    }
    return () => {};
  }, [dataKopParcAPI, dataGruParcAPI]);

  useEffect(() => {
    setRazlikePovrsina(sumPovGruZk - sumPovKopPl);
    return () => {};
  }, [sumPovGruZk, sumPovKopPl]);

  useEffect(() => {
    let sredina = (sumPovGruZk + sumPovKopPl) / 2;
    let matematika = (razlikePovrsina / sredina) * 100;
    let absRound = Math.abs(Math.round((matematika + Number.EPSILON) * 100) / 100);
    setRazlikePovrsinaProcenti(absRound);
    return () => {};
  }, [razlikePovrsina, sumPovGruZk, sumPovKopPl]);

  let templatesGru = {
    grunt: [
      {
        gruAlist: currentArrayGruP,
        gruBlist: currentArrayGruV,
        gruClist: currentArrayTeret,
      },
    ],
  };

  let templatesKop = {
    katastar: [
      {
        kopAlist: currentArrayKopP,
        kopBlist: currentArrayKopV,
      },
    ],
  };

  const GruntPodaci = () => (
    <Fragment>
      {Object.keys(templatesGru).map((idx) => {
        return (
          <div key={`zk-podaci-${idx}`} id={`zk-podaci-${idx}`} className="zk-podaci">
            {templatesGru.grunt[0].gruAlist.map((gru_aitems, ida) => {
              let uniqueZku = [...new Set(gru_aitems.map((uniqzku) => uniqzku.gru_pl))];
              let uniqueZkKo = [...new Set(gru_aitems.map((uniqzko) => uniqzko.ko))];
              // * Uslov za malu tabelu
              let malatabela;
              if (ida === 0) {
                malatabela = (
                  <tbody key={`${idx}-mala-tabela-tbody-${ida}`} id={`${idx}-mala-tabela-tbody-${ida}`}>
                    <tr>
                      <th colSpan="2" scope="row" className="bg-gray text-center fs-6 zk-stanje">
                        ZEMLJIŠNO KNJIŽNO STANJE
                      </th>
                    </tr>
                    <tr>
                      <th scope="row" width="200px" className="zk-ko-stari-premjer">
                        K.O. STAROG PREMJERA
                      </th>
                      <th scope="row" className="zk-ko-stari-premjer">
                        <span>{uniqueZkKo}</span>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row" width="200px" className="zk-ulozak">
                        {uniqueZku[0] === '99999' ? (
                          <span>ANALOGNI ZK ULOŽAK</span>
                        ) : (
                          <span>ZEMLJIŠNO-KNJIŽNI ULOŽAK</span>
                        )}
                      </th>
                      <th scope="row">{uniqueZku[0] === '99999' ? <Fragment /> : <span>{uniqueZku}</span>}</th>
                    </tr>
                  </tbody>
                );
              } else {
                malatabela = (
                  <tbody key={`${idx}-mala-tabela-tbody-${ida}`} id={`${idx}-mala-tabela-tbody-${ida}`}>
                    <tr>
                      <th scope="row" width="200px" className="zk-ko-stari-premjer">
                        K.O. STAROG PREMJERA
                      </th>
                      <th scope="row" className="zk-ko-stari-premjer">
                        <span>{uniqueZkKo}</span>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row" width="200px" className="zk-ulozak">
                        {uniqueZku[0] === '99999' ? (
                          <span>ANALOGNI ZK ULOŽAK</span>
                        ) : (
                          <span>ZEMLJIŠNO-KNJIŽNI ULOŽAK</span>
                        )}
                      </th>
                      <th scope="row">{uniqueZku[0] === '99999' ? <Fragment /> : <span>{uniqueZku}</span>}</th>
                    </tr>
                  </tbody>
                );
              }

              return (
                <Fragment key={`zk-fragment-${ida}`}>
                  <table
                    key={`${idx}-mala-tabela-${ida}`}
                    id={`${idx}-mala-tabela-${ida}`}
                    className="table table-sm table-bordered border-dark mala-tabela"
                  >
                    {malatabela}
                  </table>

                  <table
                    key={`${idx}-a-list-${ida}`}
                    id={`${idx}-a-list-${ida}`}
                    className="table table-sm table-bordered border-dark a-list"
                  >
                    <thead
                      key={`${idx}-thead-a-list-${ida}`}
                      id={`${idx}-thead-a-list-${ida}`}
                      className="align-middle text-center"
                    >
                      <tr>
                        <th colSpan="5" className="bg-gray fs-6">
                          Podaci o parcelama
                        </th>
                      </tr>
                      <tr>
                        <th scope="col" width="80px">
                          Broj
                        </th>
                        <th scope="col" width="70px">
                          Podbroj
                        </th>
                        <th scope="col">Naziv</th>
                        <th scope="col">Kultura</th>
                        <th scope="col" width="100px">
                          Površina (m<sup>2</sup>)
                        </th>
                      </tr>
                    </thead>
                    <tbody key={`${idx}-tbody-a-list-${ida}`} id={`${idx}-tbody-a-list-${ida}`} className="text-center">
                      {gru_aitems
                        .sort((a, b) =>
                          a.broj_parcele.localeCompare(b.broj_parcele, undefined, {
                            numeric: true,
                            sensitivity: 'base',
                          }),
                        )
                        .map((alist, ip) => {
                          let bp = alist.broj_parcele.split('/');
                          return (
                            <tr key={`tr-${ip}`} id={`tr-${ip}`}>
                              <td>{bp[0]}</td>
                              <td>{bp.length === 1 ? 0 : bp[1] !== '' ? bp[1] : 0}</td>
                              <td>{alist.naziv_parcele}</td>
                              <td>{alist.nacin_koristenja}</td>
                              <td>{alist.povrsina_dijela_parcele}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot key={`${idx}-tfoot-a-list-${ida}`} id={`${idx}-tfoot-a-list-${ida}`}>
                      <tr>
                        <th className="text-end" colSpan="4">
                          Ukupna površina parcela
                        </th>
                        <th className="text-center" width="100px">
                          {gru_aitems.map((zbir, i) =>
                            zbirPovrsinaGru.map((element, ie) =>
                              element.gru_pl === zbir.gru_pl && i < 1 ? (
                                <span key={i} id={i}>
                                  {element.suma}
                                </span>
                              ) : (
                                <Fragment key={`${i}-${ie}`} />
                              ),
                            ),
                          )}
                        </th>
                      </tr>
                    </tfoot>
                  </table>

                  <table
                    key={`${idx}-b-list-${ida}`}
                    id={`${idx}-b-list-${ida}`}
                    className="table table-sm table-bordered border-dark b-list"
                  >
                    <thead
                      key={`${idx}-thead-b-list-${ida}`}
                      id={`${idx}-thead-b-list-${ida}`}
                      className="align-middle text-center"
                    >
                      <tr>
                        <th colSpan="3" className="bg-gray fs-6">
                          Podaci o vlasnicima
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">Vlasnik</th>
                        <th scope="col">Adresa</th>
                        <th scope="col" width="150px">
                          Obim prava
                        </th>
                      </tr>
                    </thead>
                    {templatesGru.grunt[0].gruBlist.map((itemv, idb) =>
                      idb === ida ? (
                        <tbody
                          key={`${idx}-tbody-b-list-${idb}`}
                          id={`${idx}-tbody-b-list-${idb}`}
                          className="text-center"
                        >
                          {itemv
                            .sort(
                              (a, b) =>
                                a.udio.localeCompare(b.udio, undefined, {
                                  numeric: true,
                                  sensitivity: 'base',
                                }) || a.naziv.localeCompare(b.naziv),
                            )
                            .map((item, iv) => {
                              return (
                                <tr key={`tr-${iv}`} id={`tr-${iv}`}>
                                  <td>{item.naziv}</td>
                                  <td>{item.adresa}</td>
                                  <td>{item.udio}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      ) : (
                        <Fragment key={`${idx}-tbody-b-list-${idb}`} />
                      ),
                    )}
                  </table>

                  {uniqueZku[0] === '99999' ? (
                    <Fragment />
                  ) : (
                    <table
                      key={`${idx}-c-list-${ida}`}
                      id={`${idx}-c-list-${ida}`}
                      className="table table-sm table-bordered border-dark c-list"
                    >
                      <thead
                        key={`${idx}-thead-c-list-${ida}`}
                        id={`${idx}-thead-c-list-${ida}`}
                        className="align-middle text-center"
                      >
                        <tr>
                          <th colSpan="4" className="bg-gray fs-6">
                            C Teretni list
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" width="50px">
                            Rb.
                          </th>
                          <th scope="col">Opis</th>
                          <th scope="col" width="80px">
                            Iznos
                          </th>
                          <th scope="col" width="80px">
                            Primjedba
                          </th>
                        </tr>
                      </thead>
                      {templatesGru.grunt[0].gruClist.map((itemc, idc) =>
                        idc === ida ? (
                          <tbody
                            key={`${idx}-tbody-c-list-${idc}`}
                            id={`${idx}-tbody-c-list-${idc}`}
                            className="text-center"
                          >
                            {itemc.map((item, ic) => {
                              if (item.rb > 0 || item.rb !== null) {
                                return (
                                  <tr key={`tr-${ic}`} id={`tr-${ic}`}>
                                    <td>{item.rb}</td>
                                    <td className="text-start">{item.opis}</td>
                                    <td>{item.iznos}</td>
                                    <td>{item.primjedba}</td>
                                  </tr>
                                );
                              }
                              return (
                                <tr key={`tr1-${ic}`} id={`tr1-${ic}`}>
                                  <td></td>
                                  <td className="text-start">Nema tereta</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <Fragment key={`${idx}-tbody-c-list-${idc}`} />
                        ),
                      )}
                    </table>
                  )}
                </Fragment>
              );
            })}
          </div>
        );
      })}
    </Fragment>
  );

  const KatastarskiPodaci = () => (
    <Fragment>
      {Object.keys(templatesKop).map((idx) => {
        return (
          <div key={`kop-podaci-${idx}`} id={`kop-podaci-${idx}`} className="kat-podaci">
            {templatesKop.katastar[0].kopAlist.map((kop_aitems, ida) => {
              // * Pretezita veza provjera status (vratio 7 i 6 radi colSpan)
              let pvStatus = podaciZaglavlje2.vrsta === 'Čl. 73, stav 1 ZZK' ? 7 : 6;
              // * Unique PL i KO koji se upisuju u malu tabelu
              let uniquePl = [...new Set(kop_aitems.map((uniqpl) => uniqpl.kop_pl))];
              let uniqueKko = [...new Set(kop_aitems.map((uniqkko) => uniqkko.ko))];
              // * Uslov za malu tabelu
              let malatabela;
              if (ida === 0) {
                malatabela = (
                  <tbody key={`${idx}-mala-tabela-tbody-${ida}`} id={`${idx}-mala-tabela-tbody-${ida}`}>
                    <tr>
                      <th colSpan="2" scope="row" className="bg-gray text-center fs-6 kop-stanje">
                        KATASTARSKO STANJE
                      </th>
                    </tr>
                    <tr>
                      <th scope="row" width="200px" className="kop-ko-novi-premjer">
                        K.O. NOVOG PREMJERA
                      </th>
                      <th scope="row" className="kop-ko-novi-premjer">
                        <span>{uniqueKko}</span>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row" width="200px" className="kop-pl">
                        POSJEDOVNI LIST
                      </th>
                      <th scope="row">
                        <span>{uniquePl}</span>
                      </th>
                    </tr>
                  </tbody>
                );
              } else {
                malatabela = (
                  <tbody key={`${idx}-mala-tabela-tbody-${ida}`} id={`${idx}-mala-tabela-tbody-${ida}`}>
                    <tr>
                      <th scope="row" width="200px" className="kop-pl">
                        POSJEDOVNI LIST
                      </th>
                      <th scope="row">
                        <span>{uniquePl}</span>
                      </th>
                    </tr>
                  </tbody>
                );
              }

              return (
                <Fragment key={`kop-fragment-${ida}`}>
                  <table
                    key={`${idx}-mala-tabela-${ida}`}
                    id={`${idx}-mala-tabela-${ida}`}
                    className="table table-sm table-bordered border-dark mala-tabela"
                  >
                    {malatabela}
                  </table>

                  <table
                    key={`${idx}-a-list-${ida}`}
                    id={`${idx}-a-list-${ida}`}
                    className="table table-sm table-bordered border-dark a-list"
                  >
                    <thead
                      key={`${idx}-thead-a-list-${ida}`}
                      id={`${idx}-thead-a-list-${ida}`}
                      className="align-middle text-center"
                    >
                      <tr>
                        <th colSpan={`${pvStatus}`} className="bg-gray fs-6">
                          Podaci o parcelama
                        </th>
                      </tr>
                      <tr>
                        <th scope="col" width="80px">
                          Broj
                        </th>
                        <th scope="col" width="70px">
                          Podbroj
                        </th>
                        <th scope="col">Naziv</th>
                        <th scope="col">Kultura</th>
                        <th scope="col" width="100px">
                          Površina (m<sup>2</sup>)
                        </th>
                        <th scope="col" width="65px">
                          Entitet
                        </th>
                        {pvStatus === 7 ? (
                          <th scope="col" width="80px">
                            Pretežita veza
                          </th>
                        ) : (
                          <></>
                        )}
                      </tr>
                    </thead>
                    <tbody key={`${idx}-tbody-a-list-${ida}`} id={`${idx}-tbody-a-list-${ida}`} className="text-center">
                      {kop_aitems
                        .sort((a, b) =>
                          a.broj_parcele.localeCompare(b.broj_parcele, undefined, {
                            numeric: true,
                            sensitivity: 'base',
                          }),
                        )
                        .map((alist, ip) => {
                          let bp = alist.broj_parcele.split('/');
                          return (
                            <tr key={`tr-${ip}`} id={`tr-${ip}`}>
                              <td>{bp[0]}</td>
                              <td>{bp.length === 1 ? 0 : bp[1] !== '' ? bp[1] : 0}</td>
                              <td>{alist.naziv_parcele}</td>
                              <td>{alist.nacin_koristenja}</td>
                              <td>{alist.povrsina_dijela_parcele}</td>
                              <td>FBiH</td>
                              {pvStatus === 7 ? (
                                <td>
                                  <span>{alist.pretezita}</span>
                                </td>
                              ) : (
                                <Fragment key={`tr-${ip}`} />
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot key={`${idx}-tfoot-a-list-${ida}`} id={`${idx}-tfoot-a-list-${ida}`}>
                      <tr>
                        <th className="text-end" colSpan="4">
                          Ukupna površina parcela
                        </th>
                        <th className="text-center" width="100px">
                          {kop_aitems.map((zbir, i) =>
                            zbirPovrsinaKop.map((element, ie) =>
                              element.kop_pl === zbir.kop_pl && i < 1 ? (
                                <span key={i} id={i}>
                                  {element.suma}
                                </span>
                              ) : (
                                <Fragment key={`${i}-${ie}`} />
                              ),
                            ),
                          )}
                        </th>
                        {pvStatus === 7 ? <th colSpan="2"></th> : <th></th>}
                      </tr>
                    </tfoot>
                  </table>

                  <table
                    key={`${idx}-b-list-${ida}`}
                    id={`${idx}-b-list-${ida}`}
                    className="table table-sm table-bordered border-dark b-list"
                  >
                    <thead
                      key={`${idx}-thead-b-list-${ida}`}
                      id={`${idx}-thead-b-list-${ida}`}
                      className="align-middle text-center"
                    >
                      <tr>
                        <th colSpan="3" className="bg-gray fs-6">
                          Podaci o posjednicima
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">Posjednik</th>
                        <th scope="col">Adresa</th>
                        <th scope="col" width="150px">
                          Obim prava
                        </th>
                      </tr>
                    </thead>
                    {templatesKop.katastar[0].kopBlist.map((itempo, idb) =>
                      idb === ida ? (
                        <tbody
                          key={`${idx}-tbody-b-list-${idb}`}
                          id={`${idx}-tbody-b-list-${idb}`}
                          className="text-center"
                        >
                          {itempo
                            .sort(
                              (a, b) =>
                                a.udio.localeCompare(b.udio, undefined, {
                                  numeric: true,
                                  sensitivity: 'base',
                                }) || a.naziv.localeCompare(b.naziv),
                            )
                            .map((item, ipo) => {
                              return (
                                <tr key={`tr-${ipo}`} id={`tr-${ipo}`}>
                                  <td>{item.naziv}</td>
                                  <td>{item.adresa}</td>
                                  <td>{item.udio}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      ) : (
                        <Fragment key={`${idx}-tbody-b-list-${idb}`} />
                      ),
                    )}
                  </table>
                </Fragment>
              );
            })}
          </div>
        );
      })}
    </Fragment>
  );

  return (
    <div className="container prijavni-list-msp">
      <div className="row gx-3">
        <div className="col-auto px-0 mx-auto col-print-12">
          <div id="zaglavlje-prijavnog-lista" className="row">
            {pls.currentPls !== null ? (
              <div className="col-5">
                <h6 className="mb-0">BOSNA I HERCEGOVINA</h6>
                <h6 className="mb-0">FEDERACIJA BOSNE I HERCEGOVINE</h6>
                <h6 className="mb-0">{podaciZaglavlje.kanton}</h6>
                <h6 className="mb-0">{podaciZaglavlje.opcina}</h6>
                <h6 className="mb-0">{podaciZaglavlje.naziv_sluzbe}</h6>
                <h6 className="mb-0">{podaciZaglavlje.naziv_sluzbe_1}</h6>
                <h6 className="mb-0">{podaciZaglavlje.naziv_sluzbe_2}</h6>
              </div>
            ) : (
              <Fragment />
            )}

            {pls.currentPls !== null ? (
              <div className="col-5 ms-auto">
                <h6 className="">
                  Katastarska općina: <strong className="small">{podaciZaglavlje2.kat_ko}</strong>
                </h6>
                <h6 className="">
                  Prijavni list broj:{' '}
                  <strong className="small">
                    {pls.currentPls}/<CurrentYear />
                  </strong>
                </h6>
                <h6 className="">
                  Pravni osnov: <strong className="small">{podaciZaglavlje2.vrsta}</strong>
                </h6>
              </div>
            ) : (
              <></>
            )}

            {pls.currentPls !== null ? (
              <h4 className="fw-bold text-center mt-4 mb-5">Prijavni list za zamjenu/uspostavu zemljišne knjige</h4>
            ) : (
              <h4 className="fw-bold text-center mt-4 mb-5">Morate odabrati prijavni list da nastavite sa radom</h4>
            )}
          </div>

          {pls.currentPls !== null ? (
            <div id="zemljisno-knjizni-podaci">
              <GruntPodaci />
              <div id="gru-povrsine">
                <table className="table table-sm table-borderless mb-1" width="50%">
                  <tbody>
                    <tr>
                      <th className="text-end" colSpan="4">
                        Ukupna površina parcela:
                      </th>
                      <th className="text-end" width="165px">
                        <div className="me-3">
                          {sumPovGruZk}{' '}
                          <span>
                            m<sup>2</sup>
                          </span>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <></>
          )}

          {pls.currentPls !== null ? (
            <div id="katastarski-podaci">
              <KatastarskiPodaci />
              <div id="kop-povrsine">
                <table className="table table-sm table-borderless mb-0" width="50%">
                  <tbody className="">
                    <tr>
                      <th className="text-end" colSpan="4">
                        Ukupna površina parcela:
                      </th>
                      <th className="text-end" width="185px">
                        <div className="me-3">
                          {sumPovKopPl}{' '}
                          <span>
                            m<sup>2</sup>
                          </span>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="razlike-povrsina">
                <table className="table table-sm table-borderless align-middle mb-1" width="50%">
                  <tbody className="">
                    <tr>
                      <th className="text-end fw-normal" colSpan="4">
                        Razlika površina parcela:
                      </th>
                      <th className="text-end" width="185px">
                        <div className="razlika-povrsina me-3 text-end">
                          <div className="fw-normal">
                            {razlikePovrsina}
                            <span>
                              {' '}
                              m<sup>2</sup>
                            </span>
                          </div>
                          <span className="my-0 mx-1 fs-4 fw-normal">/</span>
                          <div>
                            {razlikePovrsinaProcenti > 20 ? (
                              <span className="fw-bold">
                                {razlikePovrsinaProcenti}
                                <span> %</span>
                              </span>
                            ) : (
                              <span className="fw-normal">
                                {razlikePovrsinaProcenti}
                                <span> %</span>
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <></>
          )}

          {pls.currentPls !== null ? (
            <div id="footer-podaci" className="row">
              <div id="nalaz" className="mt-1">
                <table className="table table-sm table-borderless align-middle mb-1">
                  <tbody className="">
                    <tr>
                      <th className="text-start">
                        <span>
                          <span>Nalaz: </span>
                          <span id="defaultni-text-nalaz" className="fw-normal">
                            Identifikacijom nekretnina je utvrđeno da navedene parcele po starom premjeru, iz
                            zemljišnoknjižnog dijela, odgovaraju parcelama navedenim u katastarskom stanju prijavnog
                            lista po novom premjeru.
                          </span>
                          <br />
                          <span className="fw-normal">{podaciNalaz}</span>
                        </span>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="potpisi" className="mb-0">
                <table className="table table-sm table-borderless align-middle">
                  <tbody>
                    <tr>
                      <th className="text-start" width="38%">
                        <p className="fw-normal mb-1">
                          Pregledom je utvrđeno da je Prijavni list izrađen u skladu sa postojećim propisima, te se kao
                          takav može provesti u zemljišnoj knjizi.
                        </p>
                      </th>
                      <th className="text-start" width=""></th>
                      <th className="text-start" width="43%">
                        <div className="align-self-start">
                          <p className="fw-bold mb-0">{podaciZaglavlje.sud_naziv}</p>
                          <p className="fw-normal mb-1">
                            dostavlja se ovaj Prijavni list u svrhu novog uspostavljanja ZK uloška za nekretninu/e
                            označenu/e u ovom Prijavnom listu
                          </p>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th className="text-start py-0" width="38%">
                        <p className="fw-normal mb-3">Rukovodilac Općinskog organa za geodetske poslove:</p>
                      </th>
                      <th className="text-start" width=""></th>
                      <th className="text-start py-0" width="43%">
                        <div className="d-flex align-content-end mb-2 fw-normal">
                          <p className="me-1 mb-0">Dana:</p>
                          <p className="tackice mb-0"></p>
                          <p className="ms-1 mb-0">
                            <CurrentYear />
                            <span>. godine.</span>
                          </p>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th className="text-center pt-3" width="38%">
                        <p className="linija"></p>
                        <p className="fw-normal mb-0">{podaciZaglavlje.pregled}</p>
                      </th>
                      <th className="text-start" width=""></th>
                      {podaciZaglavlje.rukovodioc_title === null ? (
                        <th className="text-start" width="43%">
                          <p className="fw-normal mb-3">Rukovodilac Općinskog organa za geodetske poslove:</p>
                        </th>
                      ) : (
                        <th className="text-center" width="43%">
                          {/* <p className="fw-normal mb-2">{podaciZaglavlje.rukovodioc_title}</p> */}
                        </th>
                      )}
                    </tr>
                    <tr className="align-bottom">
                      <th width="38%"></th>
                      <th className="text-center">M.P.</th>
                      <th className="text-center" width="43%">
                        {/* <p className="linija mt-2"></p>
                        <p className="fw-normal mb-0">{podaciZaglavlje.rukovodioc}</p> */}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pls: state.pls,
  lastPL: state.lastPL,
  currentPls: state.pls.currentPls,
  currentRadilisteStari: state.radiliste.currentRadilisteStari,
  currentRadilisteNovi: state.radiliste.currentRadilisteNovi,
});

export default connect(mapStateToProps)(PrijavniList);
