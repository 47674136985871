import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { auth } from '../../firebase/firebase.utils';

import { setCurrentPls } from '../../redux/pls/pls.actions';
import { setVisibleDrawer } from '../../redux/radiliste/radiliste.actions';

import AddPrijavniList from '../add-prijavni-list/add-prijavni-list.components';
import EditViewPrijavniList from '../edit-view-prijavni-list/edit-view-prijavni-list.component';
import PrintExportPrijavniList from '../print-export-prijavni-list/print-export-prijavni-list.component';
import RacunanjePovrsina from '../racunanje-povrsina/racunanje-povrsina.component';

import { ReactComponent as UnosIcon } from '../../assets/img/file-plus-duotone.svg';
import { ReactComponent as PregledIcon } from '../../assets/img/clipboard-list-check-duotone.svg';
import { ReactComponent as CheckIcon } from '../../assets/img/file-check-duotone.svg';
import { ReactComponent as DownloadIcon } from '../../assets/img/folder-download-duotone.svg';

import { ReactComponent as Logo } from '../../assets/img/logo_org.svg';
import logoGeobiro from '../../assets/img/logo_geobiro.png';

import './header.styles.scss';

import { useLocationChange } from '../../util/locationChange';

const Header = ({
  currentUser,
  setCurrentPls,
  visibleDrawer,
  setVisibleDrawer,
  currentRadilisteNovi,
  currentRadilisteStari,
}) => {
  const [lokacija, setLokacija] = useState();

  useLocationChange((location) => {
    if (location.pathname === '/') {
      setLokacija('home');
    }
    if (location.pathname === '/signin') {
      setLokacija('signin');
    }
    if (location.pathname === '/unos') {
      setLokacija('unos');
    }
    if (location.pathname === '/pregled') {
      setLokacija('pregled');
    }
    if (location.pathname === '/prijavni-listovi') {
      setLokacija('prijavni-listovi');
    }
    if (location.pathname === '/prijavni-listovi-msp') {
      setLokacija('prijavni-listovi-msp');
    }
    if (location.pathname === '/print-export') {
      setLokacija('print-export');
    }
  });

  const toggleDrawer = () => {
    setVisibleDrawer(!visibleDrawer);
  };

  return (
    <div className={`container-fluid header d-print-none ${lokacija}`}>
      <nav className='navbar fixed-top navbar-light'>
        <div className='container-fluid'>
          <div className='col-auto d-flex align-items-center justify-content-start logo-container'>
            <Link className='navbar-brand' to='/'>
              <Logo className='logo' />
            </Link>
            <a
              href='https://www.geobiro.ba/'
              rel='noreferrer noopener'
              target='_blank'>
              <img
                src={logoGeobiro}
                alt='Geobiro logo'
                className='logo-geobiro'
              />
            </a>
            {currentUser &&
            currentRadilisteStari !== '' &&
            currentRadilisteNovi !== '' &&
            lokacija !== 'home' ? (
              <div className='links ms-3'>
                <Link className='btn btn-secondary mx-1' to='/unos'>
                  Unos <UnosIcon className='unos-icons' />
                </Link>
                <Link
                  className='btn btn-secondary mx-1'
                  to='/prijavni-listovi'
                  onClick={() => {
                    setCurrentPls(null);
                  }}>
                  PLovi <CheckIcon className='check-icons' />
                </Link>
                <Link
                  className='btn btn-secondary mx-1'
                  to='/prijavni-listovi-msp'
                  onClick={() => {
                    setCurrentPls(null);
                  }}>
                  PLmsp <CheckIcon className='check-icons' />
                </Link>
                <Link
                  className='btn btn-secondary mx-1 py-1'
                  to='/print-export'>
                  <DownloadIcon className='download-icons' />
                </Link>
                <RacunanjePovrsina />
                <Link className='btn btn-secondary mx-1' to='/pregled'>
                  Pregled <PregledIcon className='unos-icons' />
                </Link>
              </div>
            ) : (
              <></>
            )}
          </div>

          {currentUser &&
          currentRadilisteStari !== '' &&
          currentRadilisteNovi !== '' &&
          lokacija === 'home' ? (
            <></>
          ) : lokacija === 'unos' ? (
            <AddPrijavniList />
          ) : lokacija === 'pregled' ? (
            <button className='btn btn-warning' onClick={toggleDrawer}>
              Prikaz kontrole
            </button>
          ) : lokacija === 'prijavni-listovi' ? (
            <EditViewPrijavniList />
          ) : lokacija === 'prijavni-listovi-msp' ? (
            <EditViewPrijavniList />
          ) : lokacija === 'print-export' ? (
            <PrintExportPrijavniList />
          ) : (
            <></>
          )}

          <div className='col-auto'>
            {currentUser ? (
              <div className='justify-content-end odjava'>
                {lokacija === 'unos' ? (
                  <></>
                ) : (
                  <div className='me-2 text-info fw-bold'>
                    {currentRadilisteNovi} - {currentRadilisteStari}
                  </div>
                )}
                <div className='d-flex justify-content-end'>
                  <span className='align-self-center small me-2 text-end fw-bold'>
                    {currentUser.displayName}
                  </span>
                  <Link
                    className='btn btn-link-dark py-0 fw-bold text-decoration-none'
                    onClick={() => auth.signOut().firebase.logout()}
                    to='/'>
                    ODJAVA
                  </Link>
                </div>
              </div>
            ) : (
              <Link className='option me-4 fw-bold' to='/signin'>
                PRIJAVA
              </Link>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  currentPls: state.pls.currentPls,
  currentRadilisteStari: state.radiliste.currentRadilisteStari,
  currentRadilisteNovi: state.radiliste.currentRadilisteNovi,
  visibleDrawer: state.radiliste.visibleDrawer,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPls: (item) => dispatch(setCurrentPls(item)),
  setVisibleDrawer: (prikazi) => dispatch(setVisibleDrawer(prikazi)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
