import React from 'react';
import { connect } from 'react-redux';

import api from '../../util/api';
import { setLastPL } from '../../redux/pls/pls.actions';
import { ReactComponent as CalculateIcon } from '../../assets/img/calculator-alt-duotone.svg';

const RacunanjePovrsina = ({
  setLastPL,
  currentRadilisteNovi,
  currentRadilisteStari,
}) => {
  // * Update state redux da osvjezi tabelu sa novim racunanjima
  const updateLastplState = () => {
    try {
      api
        .get(
          `/table/prijavni_list/?limit=99999&where=kat_ko="${currentRadilisteNovi}" and gru_ko="${currentRadilisteStari}"`
        )
        .then((response) => {
          const result = response.data.data;
          if (result !== 'No data.' && result) {
            setLastPL(result);
          }
        });
    } catch (err) {
      console.log('error: ', err);
    }
  };

  const handleClickRacunanjePovrsina = () => {
    try {
      api.get('https://app.doc.ba/api/opt/racunanjepovrsina').then(() => {
        updateLastplState();
      });
    } catch (err) {
      console.log('error: ', err);
    }
  };

  return (
    <button
      className='btn btn-warning mx-1 py-1'
      onClick={handleClickRacunanjePovrsina}>
      <CalculateIcon className='calculate-icons' />
    </button>
  );
};

const mapStateToProps = (state) => ({
  lastPL: state.pls.lastPL,
  currentRadilisteStari: state.radiliste.currentRadilisteStari,
  currentRadilisteNovi: state.radiliste.currentRadilisteNovi,
});

const mapDispatchToProps = (dispatch) => ({
  setLastPL: (pl) => dispatch(setLastPL(pl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RacunanjePovrsina);
