import React, { useState, useEffect } from 'react';

const CurrentYear = () => {
  const [date, setDate] = useState();

  const getYear = () => setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);
  return <>{date}</>;
};

export default CurrentYear;
