import React from 'react';

import { Table, ConfigProvider } from 'antd';
import 'antd/dist/antd.min.css';

import { locale } from '../../locale/bs_BA';
import { customizeRenderEmpty } from '../../util/customizeEmpty';

import './antd-table-c-list.styles.scss';

const TableCList = ({ dataTable }) => {
  // * Table Columns definitions
  const columns = [
    {
      title: 'Rb',
      dataIndex: 'redbr',
      key: 'redbr',
      width: 40,
      align: 'center',
      sorter: (a, b) => {
        a = a.redbr || '';
        b = b.redbr || '';
        return a.localeCompare(b); // ako imam null u podacima da ne breack app
      },
    },
    {
      title: 'Opis',
      dataIndex: 'opis',
      key: 'opis',
      align: 'left',
    },
    {
      title: 'Iznos',
      dataIndex: 'iznos',
      key: 'iznos',
      width: 80,
      align: 'center',
      sorter: (a, b) =>
        a.iznos.localeCompare(b.iznos, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
    },
    {
      title: 'Primjedba',
      dataIndex: 'primjedba',
      key: 'primjedba',
      width: 100,
      align: 'center',
      sorter: (a, b) => {
        a = a.primjedba || '';
        b = b.primjedba || '';
        return a.localeCompare(b); // ako imam null u podacima da ne breack app
      },
    },
  ];

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table
        columns={columns}
        dataSource={dataTable}
        locale={locale}
        className="c-list-table"
        size="small"
        pagination={false}
        sticky
      />
    </ConfigProvider>
  );
};

export default TableCList;
