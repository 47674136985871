/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';

import { setCurrentPls, setLastPL } from '../../redux/pls/pls.actions';

import api from '../../util/api';
import config from '../../config/host';
import customStyles from '../../util/selectCustomStyle';

import { ReactComponent as EditIcon } from '../../assets/img/pencil-alt-duotone.svg';
import { ReactComponent as PrintIcon } from '../../assets/img/print-duotone.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/trash-alt-duotone.svg';

import './edit-view-prijavni-list.styles.scss';

import { useLocationChange } from '../../util/locationChange';

const EditViewPrijavniList = ({
  pls,
  currentPls,
  setLastPL,
  setCurrentPls,
  currentRadilisteNovi,
  currentRadilisteStari,
}) => {
  const [lokacija, setLokacija] = useState();

  useLocationChange((location) => {
    if (location.pathname === '/prijavni-listovi') {
      setLokacija('prijavni-listovi');
    }
    if (location.pathname === '/prijavni-listovi-msp') {
      setLokacija('prijavni-listovi-msp');
    }
  });

  const [optionsPls, setOptionsPls] = useState([]);
  const [podaciNalaz, setPodaciNalaz] = useState([]);

  // * Update state in redux koristim ga na vise mjesta napisan ovdje da smanjim code
  const updateLastplState = () => {
    try {
      api
        .get(
          `/table/prijavni_list/?limit=99999&where=kat_ko="${currentRadilisteNovi}" and gru_ko="${currentRadilisteStari}"`,
        )
        .then(function (response) {
          const result = response.data.data;
          if (result !== 'No data.' && result) {
            setLastPL(result);
          }
        });
    } catch (err) {
      console.log('error: ', err);
    }
  };

  // * NALAZ Uzimanje podataka iz baze ako ima bilo gdje unesen radi edita
  useEffect(() => {
    if (currentPls !== null && currentPls > 0) {
      // uzimam iz lastPL redux state Nalaz za edit
      const found = pls.lastPL.find(({ broj }) => broj === currentPls);
      if (found) {
        const plNalazAll = pls.lastPL.filter((f) => f.broj === currentPls);
        const plNalazEmptyNotNull = plNalazAll.filter((n) => n.nalaz !== '');
        if (plNalazEmptyNotNull.length > 0) {
          setPodaciNalaz(plNalazEmptyNotNull[0].nalaz);
        } else {
          setPodaciNalaz([]);
        }
      }
    }
  }, [currentPls, pls.lastPL]);

  // ! LIMIT promjenjen
  // * GET option liste za Select po PL
  useEffect(() => {
    let one = `${config.API_HOST}table/prijavni_list_multisp/?where=kat_ko='${currentRadilisteNovi}' and gru_ko='${currentRadilisteStari}'`;
    let two = `${config.API_HOST}table/prijavni_list_select/?columns=broj&limit=10000&where=kat_ko="${currentRadilisteNovi}" and gru_ko="${currentRadilisteStari}"`;

    const reqOne = axios.get(one);
    const reqTwo = axios.get(two);

    try {
      axios.all([reqOne, reqTwo]).then(
        axios.spread((...response) => {
          const result1 = response[0].data.data;
          const result2 = response[1].data.data;

          let tempOptionsPls = [];
          let singlePls = [];
          let multispPls = [];

          if (lokacija === 'prijavni-listovi') {
            if (result1 !== 'No data.' && result1) {
              result1.map((multi) => {
                return multispPls.push(multi.broj);
              });

              if (result2 !== 'No data.' && result2) {
                result2.map((singl) => {
                  return singlePls.push(singl.broj);
                });

                let filteredArr = singlePls.filter((el) => !multispPls.includes(el));

                filteredArr.map((broj) => {
                  return tempOptionsPls.push({ value: broj, label: broj });
                });
                setOptionsPls(tempOptionsPls);
              }
            } else if (result2 !== 'No data.' && result2) {
              result2.map((singl) => {
                return tempOptionsPls.push({
                  value: singl.broj,
                  label: singl.broj,
                });
              });
              setOptionsPls(tempOptionsPls);
            }
          } else if (lokacija === 'prijavni-listovi-msp') {
            if (result1 === 'No data.') {
              tempOptionsPls = [];
              setOptionsPls(tempOptionsPls);
            }
            if (result1 !== 'No data.' && result1) {
              result1.map((multi) => {
                return tempOptionsPls.push({
                  value: multi.broj,
                  label: multi.broj,
                });
              });
              setOptionsPls(tempOptionsPls);
            }
          }
        }),
      );
    } catch (err) {
      console.log('error: ', err);
    }
  }, [currentRadilisteNovi, currentRadilisteStari, lokacija]);

  // * Edit Vrsta
  const [odabranaVrsta, setOdabranaVrsta] = useState(null);
  const optionsVrsta = [
    {
      value: 'Čl. 88, stav 1 ZZK',
      label: 'Čl. 88, stav 1 ZZK',
    },
    {
      value: 'Čl. 88, stav 2 ZZK',
      label: 'Čl. 88, stav 2 ZZK',
    },
    {
      value: 'Čl. 73, stav 1 ZZK',
      label: 'Čl. 73, stav 1 ZZK',
    },
    {
      value: 'Čl. 63-67 ZZK',
      label: 'Čl. 63-67 ZZK',
    },
  ];

  // ! LIMIT promjenjen
  const handleEditVrstaSubmit = (event) => {
    event.preventDefault();
    const editVrstaSwal = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success me-3',
        cancelButton: 'btn btn-secondary ms-3',
      },
      buttonsStyling: false,
    });
    editVrstaSwal
      .fire({
        title: 'Promjena Vrste',
        text: 'Ovom akcijom ćete promjeniti vrstu za odabrani prijavni list.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Nastavi',
        cancelButtonText: 'Odustani',
        reverseButtons: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          try {
            api
              .put(
                `/table/prijavni_list/update?columns=vrsta&value="${odabranaVrsta}"&where=broj=${pls.currentPls} and kat_ko="${currentRadilisteNovi}" and gru_ko="${currentRadilisteStari}"`,
              )
              .then(function (response) {
                const result = response.data.data;
                if (result !== 'No data.' && result) {
                  editVrstaSwal.fire({
                    icon: 'success',
                    title: 'Uspješno!',
                    text: 'Podaci za vrstu su ažurirani.',
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  // Vracacnje podataka u redux state
                  updateLastplState();
                }
              });
          } catch (err) {
            console.log('error: ', err);
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          editVrstaSwal.fire({
            icon: 'info',
            title: 'Otkazano!',
            text: 'Vaši podaci su nepromijenjeni.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
  };

  // ! Promjena
  // * Edit Nalaz
  const handleEditNalazSubmit = () => {
    // Ovdje ubacio da ucita podataka redux state ako je bilo promjena ranije
    updateLastplState();

    (async () => {
      const editNalazSwal = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success me-3',
          cancelButton: 'btn btn-secondary ms-3',
        },
        buttonsStyling: false,
      });
      await editNalazSwal
        .fire({
          title: `Prijavni list ${pls.currentPls}`,
          text: 'promjena nalaza',
          input: 'textarea',
          inputValue: podaciNalaz || null,
          inputPlaceholder: 'Ovdje unesite tekst nalaza...',
          showCancelButton: true,
          confirmButtonText: 'Nastavi',
          cancelButtonText: 'Odustani',
          reverseButtons: false,
        })
        .then((result) => {
          let unos = Object.values(result);
          if (result.isConfirmed) {
            try {
              api
                .put(
                  `/table/prijavni_list/update?columns=nalaz&value="${unos[3]}"&where=broj=${pls.currentPls} and kat_ko="${currentRadilisteNovi}" and gru_ko="${currentRadilisteStari}"`,
                )
                .then(function (response) {
                  const result = response.data.data;
                  if (result !== 'No data.' && result) {
                    editNalazSwal.fire({
                      icon: 'success',
                      title: 'Uspješno!',
                      text: 'Podaci za nalaz su ažurirani.',
                      showConfirmButton: false,
                      timer: 2500,
                    });
                    // Vracacnje podataka u redux state
                    updateLastplState();
                  }
                });
            } catch (err) {
              console.log('error: ', err);
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            editNalazSwal.fire({
              icon: 'info',
              title: 'Otkazano!',
              text: 'Vaši podaci su nepromijenjeni.',
              showConfirmButton: false,
              timer: 2000,
            });
          }
        });
    })();
  };

  // * Set value for default selection PL za reset pls.currentPls
  const [selectedValue, setSelectedValue] = useState(null);

  // * HandleChangePlOdabir event of the dropdown PL-ova za reset pls.currentPls
  const handleChangePlOdabir = (event) => {
    // Overwrite the event with your own object if it doesn't exist
    if (!event) {
      event = {
        value: '',
      };
    }
    setSelectedValue(event.value);
    setCurrentPls(event.value);
  };

  const handleChangeVrsta = (event) => {
    // Overwrite the event with your own object if it doesn't exist
    if (!event) {
      event = {
        value: '',
      };
    }
    setOdabranaVrsta(event.value);
  };

  const handleDeleteCijeliPl = (event) => {
    event.preventDefault();
    const deleteCijeliPlSwal = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success me-3',
        cancelButton: 'btn btn-danger ms-3',
      },
      buttonsStyling: false,
    });
    deleteCijeliPlSwal
      .fire({
        title: `Brisanje PL-a ${pls.currentPls}`,
        html: `Ovom akcijom<br />odabrani prijavni list <br />će biti trajno obrisan sa svim unosima.<br />Ovu akciju je nemoguće povratiti.`,
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Obriši',
        cancelButtonText: 'Odustani',
        reverseButtons: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          try {
            api
              .post(`/table/prijavni_list/deletepl/?brojpl=${pls.currentPls}&ko="${currentRadilisteNovi}"`)
              .then(() => {
                deleteCijeliPlSwal.fire({
                  icon: 'success',
                  title: 'Uspješno!',
                  text: 'Odabrani PL je obrisan.',
                  showConfirmButton: false,
                  timer: 1500,
                });
                // Vracacnje podataka u redux state
                updateLastplState();
                // Postavljanje selected i curentPls na null
                setSelectedValue(null);
                // Reset optionsPls jer smo brisali currentPls
                let tempOptionsPls = [];
                try {
                  api
                    .get(
                      `/table/prijavni_list_select/?limit=10000&where=kat_ko="${currentRadilisteNovi}" and gru_ko="${currentRadilisteStari}"`,
                    )
                    .then((response) => {
                      const result = response.data.data;
                      if (result !== 'No data.' && result) {
                        result.map((opt) => {
                          return tempOptionsPls.push({
                            value: opt.broj,
                            label: opt.broj,
                          });
                        });
                        setOptionsPls(tempOptionsPls);
                      }
                    });
                } catch (err) {
                  console.log('error: ', err);
                }
              });
          } catch (err) {
            console.log('error: ', err);
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          deleteCijeliPlSwal.fire({
            icon: 'info',
            title: 'Otkazano!',
            text: 'Vaši podaci su nepromijenjeni.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
  };

  useEffect(() => {
    if (selectedValue === null || selectedValue === '') {
      setCurrentPls(null);
    }
  }, [selectedValue, setCurrentPls]);

  return (
    <div className="col-auto ms-2 me-auto">
      <div className="d-flex justify-content-between align-items-center">
        <div className={`select-wrapper ${pls.currentPls === null ? 'odabir' : ''}`}>
          <Select
            isClearable
            id="select-current-pl"
            placeholder="Prijavni list"
            styles={customStyles}
            width="130px"
            menuColor="#333"
            value={optionsPls.find((obj) => obj.value === selectedValue)}
            options={optionsPls}
            noOptionsMessage={() => {
              let emptyAndSearchNoResults = `${
                optionsPls.length === 0 ? 'Nema unosa' : 'Nema rezultata pokušajte novi unos'
              }`;
              return emptyAndSearchNoResults;
            }}
            onChange={handleChangePlOdabir}
          />
        </div>
        <form onSubmit={handleEditVrstaSubmit} className="d-flex">
          <div className={`edit-print-button ${pls.currentPls === null ? 'disabled' : ''}`}>
            <div className={`edit-print-button ${odabranaVrsta === null ? 'disabled' : ''}`}>
              <button
                type="submit"
                className={`btn btn-warning mx-2 ${odabranaVrsta === null || odabranaVrsta === '' ? 'disabled' : ''}`}
              >
                Uredi vrstu <EditIcon className="edit-icons" />
              </button>
            </div>
          </div>
          <div className={`select-wrapper-vrsta ${pls.currentPls === null ? 'disabled' : ''}`}>
            <Select
              isClearable
              id="promjena-vrste"
              name="promjena-vrste"
              placeholder="Promjena vrste"
              isDisabled={pls.currentPls === null ? true : false}
              styles={customStyles}
              width="180px"
              menuColor="#333"
              value={optionsVrsta.find((o) => o.value === odabranaVrsta)}
              options={optionsVrsta}
              noOptionsMessage={() => 'Odaberite vrstu'}
              onChange={handleChangeVrsta}
            />
          </div>
        </form>
        <div className={`edit-print-button ${pls.currentPls === null ? 'disabled' : ''}`}>
          <button
            className={`btn btn-warning ms-2 ${pls.currentPls === null ? 'disabled' : ''}`}
            onClick={() => {
              handleEditNalazSubmit();
            }}
          >
            Uredi nalaz <EditIcon className="edit-icons" />
          </button>
          <button
            className={`btn btn-outline-secondary ms-2 ${pls.currentPls === null ? 'disabled' : ''}`}
            onClick={() => {
              window.print();
            }}
          >
            Print <PrintIcon className="print-icons" />
          </button>
          <button
            className={`btn btn-outline-danger ms-5 ${pls.currentPls === null ? 'disabled' : ''}`}
            onClick={handleDeleteCijeliPl}
          >
            Brisanje <DeleteIcon className="delete-icons" />
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pls: state.pls,
  lastPL: state.lastPL,
  currentPls: state.pls.currentPls,
  currentRadilisteStari: state.radiliste.currentRadilisteStari,
  currentRadilisteNovi: state.radiliste.currentRadilisteNovi,
});

const mapDispatchToProps = (dispatch) => ({
  setLastPL: (updatePl) => dispatch(setLastPL(updatePl)),
  setCurrentPls: (item) => dispatch(setCurrentPls(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditViewPrijavniList);
