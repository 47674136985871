import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';

import {
  setActivePls,
  setLastPL,
  setIdentGru,
  setIdentKat,
  setStatusGru,
  setStatusKat,
} from '../../redux/pls/pls.actions';
import {
  setParcelaGrunt,
  setParcelaKatastar,
  setOptionsGru,
  setOptionsKat,
} from '../../redux/parcele/parcele.actions';

import api from '../../util/api';
import config from '../../config/host';
import partialContains from '../../util/partialContains';
import customStyles from '../../util/selectCustomStyle';

import { ReactComponent as SaveIcon } from '../../assets/img/save-light.svg';

import {
  ToastBottom,
  ToastTop,
} from '../../components/swal-modal-toast/swal-modal-toast.components';

import './add-prijavni-list.styles.scss';

const AddPrijavniList = ({
  parcele,
  pls,
  activePls,
  setActivePls,
  setLastPL,
  setIdentGru,
  setIdentKat,
  setStatusGru,
  setStatusKat,
  currentRadilisteNovi,
  currentRadilisteStari,
  setParcelaGrunt,
  setParcelaKatastar,
  parcelaGrunt,
  parcelaKatastar,
  setOptionsGru,
  setOptionsKat,
}) => {
  // ! LIMIT
  // * GET podataka Identifikacija i Statistika KAT i GRU - to redux state
  // Napisao ove funkcije jer mi treba da ih pozivam nakon submit
  const onAddNewUpdateIdentKat = () => {
    try {
      api
        .get(
          `/table/prijavni_list_ident_kat/?limit=10000&columns=broj, parc&where=ko="${currentRadilisteNovi}"`
        )
        .then((response) => {
          const result = response.data.data;
          // result !== 'No data.' && result
          if (result) {
            setIdentKat(result);
          }
        });
    } catch (err) {
      console.log('error: ', err);
    }
  };
  const onAddNewUpdateIdentGru = () => {
    try {
      api
        .get(
          `/table/prijavni_list_ident_gru/?limit=10000&columns=broj, parc&where=gko="${currentRadilisteStari}"`
        )
        .then((response) => {
          const result = response.data.data;
          // result !== 'No data.' && result
          if (result) {
            setIdentGru(result);
          }
        });
    } catch (err) {
      console.log('error: ', err);
    }
  };
  const onAddNewUpdateStatus = () => {
    // Ne dirati redoslijed od njega zavisi prikaz
    let one = `${config.API_HOST}table/prijavni_list/totalSize?where=kat_ko="${currentRadilisteNovi}"`;
    let two = `${config.API_HOST}table/prijavni_list_statistika_kat?where=ko="${currentRadilisteNovi}"`;
    let tree = `${config.API_HOST}table/prijavni_list/totalSize?where=gru_ko="${currentRadilisteStari}"`;
    let four = `${config.API_HOST}table/prijavni_list_statistika_gru?where=ko="${currentRadilisteStari}"`;
    let five = `${config.API_HOST}table/prijavni_list_ident_gru/?limit=10000&columns=broj, parc&where=ko="${currentRadilisteNovi}"`;
    let six = `${config.API_HOST}table/gru_a_list/totalSize?where=ko="${currentRadilisteStari}"`;
    let seven = `${config.API_HOST}table/kop_a_list/totalSize?where=ko="${currentRadilisteNovi}"`;

    const reqOne = axios.get(one);
    const reqTwo = axios.get(two);
    const reqTree = axios.get(tree);
    const reqFour = axios.get(four);
    const reqFive = axios.get(five);
    const reqSix = axios.get(six);
    const reqSeven = axios.get(seven);
    try {
      axios
        .all([reqOne, reqTwo, reqTree, reqFour, reqFive, reqSix, reqSeven])
        .then(
          axios.spread((...response) => {
            const resultKat1 = response[0].data;
            const resultKat2 = response[1].data.data[0];
            const resultGru1 = response[2].data;
            const resultGru2 = response[3].data.data[0];
            const resultGru3 = response[4].data.data.length;
            const resultGru4 = response[5].data;
            const resultKat3 = response[6].data;

            const joinKat = [resultKat1, resultKat2, resultKat3];
            const joinGru = [resultGru1, resultGru2, resultGru3, resultGru4];
            if (
              resultKat1 !== 'No data.' &&
              resultKat1 &&
              resultKat2 !== 'No data.' &&
              resultKat2 &&
              resultKat3 !== 'No data.' &&
              resultKat3
            ) {
              setStatusKat(joinKat);
            }
            if (
              resultGru1 !== 'No data.' &&
              resultGru1 &&
              resultGru2 !== 'No data.' &&
              resultGru2 &&
              resultGru3 !== 'No data.' &&
              resultGru3 &&
              resultGru4 !== 'No data.' &&
              resultGru4
            ) {
              setStatusGru(joinGru);
            }
          })
        )
        .catch((error) => {
          console.log('error: ', error);
        });
    } catch (err) {
      console.log('error: ', err);
    }
  };

  // * Form data Vrsta i Napomena ostale uzimam iz redux state
  const [formParameters, setFormParameters] = useState({
    odabranaVrsta: null,
    napomena: '',
  });
  const optionsVrsta = [
    {
      value: 'Čl. 88, stav 1 ZZK',
      label: 'Čl. 88, stav 1 ZZK',
    },
    {
      value: 'Čl. 88, stav 2 ZZK',
      label: 'Čl. 88, stav 2 ZZK',
    },
    {
      value: 'Čl. 73, stav 1 ZZK',
      label: 'Čl. 73, stav 1 ZZK',
    },
    {
      value: 'Čl. 63-67 ZZK',
      label: 'Čl. 63-67 ZZK',
    },
  ];

  // * Da upise u input zadnji broj za prijavni list automatski
  const loadDefault = () => {
    let inputPrijavniList = document.getElementById('prijavniList');
    const prijavniListValue = inputPrijavniList.value;

    if (!prijavniListValue) {
      try {
        api
          .get(
            `/table/prijavni_list_max/?where=kat_ko="${currentRadilisteNovi}"`
          )
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              const defaultNumber = response.data.data[0].broj;
              inputPrijavniList.value = defaultNumber;
              setActivePls(defaultNumber);
            }
          });
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };
  // * Handle promjena broja prijavnog lista u input radi update redux state
  const handleChangeActive = (event) => {
    // Postavljeno u number jer se kod selecta i kod unosa type razlikuje a poredi se sa number iz baze
    setActivePls(Number(event.target.value));
  };

  const handleChangeVrsta = (event) => {
    // Overwrite the event with your own object if it doesn't exist
    if (!event) {
      event = {
        value: '',
      };
    }
    setFormParameters({
      ...formParameters,
      odabranaVrsta: event.value,
    });
  };

  const handleChangeNapomena = (event) => {
    setFormParameters({
      ...formParameters,
      napomena: event.target.value,
    });
  };

  // ! LIMIT
  const handleChangeKatastarParcelaText = (event) => {
    var textLength = event.length;
    if (textLength >= 1) {
      try {
        api
          .get(
            `/table/kop_select/distinct/?limit=99999&where=broj_parcele LIKE('__${event}__') and ko="${currentRadilisteNovi}"&order=CAST(IF(LEFT(broj_parcele, POSITION('/' in broj_parcele)) = 0, broj_parcele, LEFT(broj_parcele, POSITION('/' in broj_parcele)-1)) AS INTEGER), CAST(IF(LEFT(broj_parcele, POSITION('/' in broj_parcele)) = 0, 0, RIGHT(broj_parcele, (LENGTH(broj_parcele) - POSITION('/' in broj_parcele)))) AS INTEGER)`
          )
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              const podaciParcPl = result.map((s) => {
                return {
                  value: s.broj_parcele,
                  label: s.broj_parcele,
                };
              });
              setOptionsKat(podaciParcPl);
            }
          });
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };

  // * COMPARE KAT
  const handleChangeKatastarParcela = (event) => {
    // Overwrite the event with your own object if it doesn't exist
    if (!event) {
      event = {
        value: '',
      };
    }
    // Provjera da li se nalazi parcela i novi broj prijavnog lista za unos
    // sa podacima u tabeli poredeci broj prijavnog lista i parcelu zajedno
    let izInputa = { broj: activePls, parc: event.value };
    const containing = pls.identKat.find((object) =>
      partialContains(object, izInputa)
    );
    const found = pls.identKat.find((x) => x.parc === event.value);
    // Slucaj kada treba pustiti odabir jer je u istom prijavnom listu
    if (containing) {
      ToastBottom.fire({
        icon: 'success',
        title: 'Parcela broj:',
        text: `${event.value} je odabrana!`,
      });
      setParcelaKatastar(event.value);
    }
    // Slucaj kada se odabrana parcela nalazi u nekom drugom prijavnom listu
    // uslovno zabranjen odabir jer korisnik moze reci da zeli nastaviti
    if (!containing) {
      if (found) {
        const identSwalK = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success me-3',
            cancelButton: 'btn btn-danger ms-3',
          },
          buttonsStyling: false,
        });
        identSwalK
          .fire({
            title: 'Upozorenje',
            html: `Parcela <span class="fw-bold">${found.parc}</span> je već identifikovana u PL <span class="fw-bold">${found.broj}</span>`,
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: `Nastavi`,
            cancelButtonText: `Odustani`,
            reverseButtons: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              identSwalK.fire({
                icon: 'success',
                title: 'Odabrano! ',
                text: 'Odabrali ste već identifikovanu parcelu.',
                showConfirmButton: false,
                timer: 1500,
              });
              setParcelaKatastar(event.value);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              ToastBottom.fire({
                icon: 'info',
                title: 'Otkazano! ',
                text: 'Stanje odabira nema promjena.',
              });
            }
          });
      } else {
        if (event.value !== '') {
          ToastBottom.fire({
            icon: 'success',
            title: 'Parcela broj:',
            text: `${event.value} je odabrana!`,
          });
        }
        setParcelaKatastar(event.value);
      }
    }
  };

  // ! LIMIT
  const handleChangeGruntParcelaText = (event) => {
    var textLength = event.length;
    if (textLength >= 1) {
      try {
        api
          .get(
            `/table/gru_select/distinct/?limit=99999&where=broj_parcele LIKE('__${event}__') and ko="${currentRadilisteStari}"&order=CAST(IF(LEFT(broj_parcele, POSITION('/' in broj_parcele)) = 0, broj_parcele, LEFT(broj_parcele, POSITION('/' in broj_parcele)-1)) AS INTEGER), CAST(IF(LEFT(broj_parcele, POSITION('/' in broj_parcele)) = 0, 0, RIGHT(broj_parcele, (LENGTH(broj_parcele) - POSITION('/' in broj_parcele)))) AS INTEGER)`
          )
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              const podaciParcZk = result.map((s) => {
                return {
                  value: s.broj_parcele,
                  label: s.broj_parcele,
                };
              });
              setOptionsGru(podaciParcZk);
            }
          });
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };

  // * COMPARE GRU
  const handleChangeGruntParcela = (event) => {
    // Overwrite the event with your own object if it doesn't exist
    if (!event) {
      event = {
        value: '',
      };
    }
    // Provjera da li se nalazi parcela i novi broj prijavnog lista za unos
    // sa podacima u tabeli poredeci broj prijavnog lista i parcelu zajedno
    let izInputa = { broj: activePls, parc: event.value };
    const containing = pls.identGru.find((object) =>
      partialContains(object, izInputa)
    );
    const found = pls.identGru.find((x) => x.parc === event.value);
    // Slucaj kada treba pustiti odabir jer je u istom prijavnom listu
    if (containing) {
      ToastBottom.fire({
        icon: 'success',
        title: 'Parcela broj:',
        text: `${event.value} je odabrana!`,
      });
      setParcelaGrunt(event.value);
    }
    // Slucaj kada se odabrana parcela nalazi u nekom drugom prijavnom listu
    // uslovno zabranjen odabir jer korisnik moze reci da zeli nastaviti
    if (!containing) {
      if (found) {
        const identSwalG = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success me-3',
            cancelButton: 'btn btn-danger ms-3',
          },
          buttonsStyling: false,
        });
        identSwalG
          .fire({
            title: 'Upozorenje',
            html: `Parcela <span class="fw-bold">${found.parc}</span> je već identifikovana u PL <span class="fw-bold">${found.broj}</span>`,
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: `Nastavi`,
            cancelButtonText: `Odustani`,
            reverseButtons: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              identSwalG.fire({
                icon: 'success',
                title: 'Odabrano! ',
                text: 'Odabrali ste već identifikovanu parcelu.',
                showConfirmButton: false,
                timer: 1500,
              });
              setParcelaGrunt(event.value);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              ToastBottom.fire({
                icon: 'info',
                title: 'Otkazano! ',
                text: 'Stanje odabira nema promjena.',
              });
            }
          });
      } else {
        if (event.value !== '') {
          ToastBottom.fire({
            icon: 'success',
            title: 'Parcela broj:',
            text: `${event.value} je odabrana!`,
          });
        }
        setParcelaGrunt(event.value);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      broj: activePls,
      broj_parcele_kop: parcelaKatastar,
      broj_parcele_grunt: parcelaGrunt,
      vrsta: formParameters.odabranaVrsta,
      kat_ko: currentRadilisteNovi,
      gru_ko: currentRadilisteStari,
      napomena: formParameters.napomena,
    };

    let izInputa = {
      broj: formData.broj,
      broj_parcele_kop: formData.broj_parcele_kop,
      broj_parcele_grunt: formData.broj_parcele_grunt,
    };
    // Provjera iput i select da li su vec uneseni u tabelu Prijavnog lista
    const containing = pls.lastPL.find((object) =>
      partialContains(object, izInputa)
    );

    if (containing) {
      ToastBottom.fire({
        icon: 'warning',
        title: 'Upozorenje!',
        text: 'Snimili ste identične podatke ranije.',
      });
    } else {
      if (parcelaKatastar !== '' && parcelaGrunt !== '' && formData.broj > 0) {
        try {
          api
            .post(
              `/table/prijavni_list/add?where=kat_ko="${currentRadilisteNovi}" and gru_ko="${currentRadilisteStari}"`,
              formData
            )
            .then((response) => {
              if (response.status !== 200) {
                ToastBottom.fire({
                  icon: 'error',
                  title: 'Greška!',
                  text: 'Došlo je do greške molimo da pokušate ponovo.',
                });
              } else if (response.status === 200) {
                const result = response.data.data;
                if (result !== 'No data.' && result) {
                  ToastBottom.fire({
                    icon: 'success',
                    title: `PL ${activePls}`,
                    html: `KAT broj: <strong>${parcelaKatastar}</strong><br/>GRU broj: <strong>${parcelaGrunt}</strong><br/>Vrsta: <strong>${formParameters.odabranaVrsta}</strong>`,
                  });
                  setLastPL(result);
                  // Poziv za refresh podataka nakon unosa za identKat, identGru i status
                  onAddNewUpdateIdentKat();
                  onAddNewUpdateIdentGru();
                  onAddNewUpdateStatus();
                }
              }
            });
        } catch (err) {
          console.log('error: ', err);
        }
      } else {
        ToastTop.fire({
          icon: 'warning',
          title: 'Upozorenje!',
          text: 'Broj prijavnog lista, broj parcele katastra i broj parcele grunta su obavezna polja i ne mogu biti prazna!',
        });
      }
    }
  };

  return (
    <div className='col-auto ms-sm-0 ms-md-2 ms-lg-3 me-auto from-prijavni-list'>
      <form onSubmit={handleSubmit} className='px-0'>
        <div className='row align-items-center justify-content-start gx-1'>
          <div className='col align-self-center text-end'>
            <p className='m-0'>
              <strong>UNOS</strong>
            </p>
          </div>
          <div className='col'>
            <input
              id='prijavniList'
              name='prijavniList'
              type='number'
              min='1'
              className='form-control'
              placeholder='Prijavni list'
              aria-label='Prijavni list'
              autoComplete='off'
              onClick={loadDefault}
              onChange={handleChangeActive}
            />
          </div>
          <div className='col'>
            <div className='select-wrapper'>
              <Select
                isClearable
                id='selectParcelaKatastar'
                name='selectParcelaKatastar'
                placeholder='Parcela KAT'
                styles={customStyles}
                width='140px'
                menuColor='#333'
                value={parcele.optionsKat?.find(
                  (o) => o.value === parcelaKatastar
                )}
                options={parcele.optionsKat}
                noOptionsMessage={() => 'Unesite broj za pretragu'}
                onInputChange={handleChangeKatastarParcelaText}
                onChange={handleChangeKatastarParcela}
              />
            </div>
          </div>
          <div className='col'>
            <div className='select-wrapper'>
              <Select
                isClearable
                id='selectParcelaGrunt'
                name='selectParcelaGrunt'
                placeholder='Parcela GRU'
                styles={customStyles}
                width='140px'
                menuColor='#333'
                value={parcele.optionsGru?.find(
                  (o) => o.value === parcelaGrunt
                )}
                options={parcele.optionsGru}
                noOptionsMessage={() => 'Unesite broj za pretragu'}
                onInputChange={handleChangeGruntParcelaText}
                onChange={handleChangeGruntParcela}
              />
            </div>
          </div>
          <div className='col'>
            <div className='select-wrapper-vrsta'>
              <Select
                isClearable
                id='odabranaVrsta'
                name='odabranaVrsta'
                placeholder='Vrsta'
                styles={customStyles}
                width='170px'
                menuColor='#333'
                options={optionsVrsta}
                noOptionsMessage={() => 'Odaberite vrstu'}
                onChange={handleChangeVrsta}
              />
            </div>
          </div>
          <div className='col napomena'>
            <textarea
              id='napomena'
              name='napomena'
              placeholder='Napomena'
              aria-label='Napomena'
              autoComplete='off'
              className='form-control'
              rows={1}
              onChange={handleChangeNapomena}></textarea>
          </div>
          <div className='col-auto'>
            <button type='submit' className='btn btn-secondary'>
              Snimi <SaveIcon className='save-icons' />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  koppl: state.koppl,
  gruzk: state.gruzk,
  pls: state.pls,
  parcele: state.parcele,
  activePls: state.pls.activePls,
  currentPls: state.pls.currentPls,
  lastPL: state.pls.lastPL,
  currentRadilisteStari: state.radiliste.currentRadilisteStari,
  currentRadilisteNovi: state.radiliste.currentRadilisteNovi,
  parcelaGrunt: state.parcele.parcelaGrunt,
  parcelaKatastar: state.parcele.parcelaKatastar,
  identKat: state.pls.identKat,
  identGru: state.pls.identGru,
  statusKat: state.pls.statusKat,
  statusGru: state.pls.statusGru,
  optionsGru: state.parcele.parcelaGru,
  optionsKat: state.parcele.parcelaKat,
});

const mapDispatchToProps = (dispatch) => ({
  setLastPL: (pl) => dispatch(setLastPL(pl)),
  setActivePls: (activepl) => dispatch(setActivePls(activepl)),
  setParcelaGrunt: (parcelaG) => dispatch(setParcelaGrunt(parcelaG)),
  setParcelaKatastar: (parcelaK) => dispatch(setParcelaKatastar(parcelaK)),
  setIdentKat: (identkatupdate) => dispatch(setIdentKat(identkatupdate)),
  setIdentGru: (identgruupdate) => dispatch(setIdentGru(identgruupdate)),
  setStatusKat: (statuskatupdate) => dispatch(setStatusKat(statuskatupdate)),
  setStatusGru: (statusgruupdate) => dispatch(setStatusGru(statusgruupdate)),
  setOptionsGru: (optionG) => dispatch(setOptionsGru(optionG)),
  setOptionsKat: (optionK) => dispatch(setOptionsKat(optionK)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPrijavniList);
