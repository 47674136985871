import React from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { Table, Typography, ConfigProvider } from 'antd';

import { setParcelaGrunt, setParcelaKatastar, setOptionsGru, setOptionsKat } from '../../redux/parcele/parcele.actions';

import { ToastBottom } from '../swal-modal-toast/swal-modal-toast.components';
import { ReactComponent as CircleIcons } from '../../assets/img/circle-solid.svg';
import { locale } from '../../locale/bs_BA';
import { customizeRenderEmpty } from '../../util/customizeEmpty';
import partialContains from '../../util/partialContains';

import 'antd/dist/antd.min.css';
import './antd-table-a-list.styles.scss';

const TableAList = ({
  dataTable,
  ident,
  spnp,
  activePls,
  setParcelaKatastar,
  setParcelaGrunt,
  setOptionsGru,
  setOptionsKat,
}) => {
  const { Text } = Typography;

  // * SWAL alert za odabir parcela iz tabele pregleda Katastarskih podataka
  const selectedParcelaFromRow = (parcela) => {
    // Provjera da li se nalazi parcela i novi broj prijavnog lista za unos
    // sa podacima u tabeli poredeci borj prijavnog lista i parcelu zajedno
    let izInputa = { broj: activePls, parc: parcela };
    const containing = ident.find((object) => partialContains(object, izInputa));
    const found = ident.find((x) => x.parc === parcela);
    // Slucaj kada treba pustiti odabir jer je u istom prijavnom listu
    if (containing) {
      ToastBottom.fire({
        icon: 'success',
        title: 'Parcela broj:',
        text: `${parcela} je odabrana!`,
      });
      if (spnp === 'np') {
        setOptionsKat([{ value: parcela, label: parcela }]);
        setParcelaKatastar(parcela);
      }
      if (spnp === 'sp') {
        setOptionsGru([{ value: parcela, label: parcela }]);
        setParcelaGrunt(parcela);
      }
    }
    // Slucaj kada se odabrana parcela nalazi u nekom drugom prijavnom listu
    // uslovno zabranjen odabir jer korisnik moze reci da zeli nastaviti
    if (!containing) {
      if (found) {
        const identSwal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success me-3',
            cancelButton: 'btn btn-danger ms-3',
          },
          buttonsStyling: false,
        });
        identSwal
          .fire({
            title: 'Upozorenje',
            html: `Parcela <span class="fw-bold">${found.parc}</span> je već identifikovana u PL <span class="fw-bold">${found.broj}</span>`,
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: `Nastavi`,
            cancelButtonText: `Odustani`,
            reverseButtons: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              identSwal.fire({
                icon: 'success',
                title: 'Odabrano! ',
                text: 'Odabrali ste već identifikovanu parcelu.',
                showConfirmButton: false,
                timer: 1500,
              });
              if (spnp === 'np') {
                setOptionsKat([{ value: parcela, label: parcela }]);
                setParcelaKatastar(parcela);
              }
              if (spnp === 'sp') {
                setOptionsGru([{ value: parcela, label: parcela }]);
                setParcelaGrunt(parcela);
              }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              ToastBottom.fire({
                icon: 'info',
                title: 'Otkazano! ',
                text: 'Stanje odabira nema promjena.',
              });
            }
          });
      } else {
        ToastBottom.fire({
          icon: 'success',
          title: 'Parcela broj: ',
          text: `${parcela} je odabrana!`,
        });
        if (spnp === 'np') {
          setOptionsKat([{ value: parcela, label: parcela }]);
          setParcelaKatastar(parcela);
        }
        if (spnp === 'sp') {
          setOptionsGru([{ value: parcela, label: parcela }]);
          setParcelaGrunt(parcela);
        }
      }
    }
  };

  // * Table Columns definitions
  const columns = [
    {
      title: <CircleIcons />,
      dataIndex: 'tags',
      key: 'tags',
      className: 'tags',
      width: 6,
      onCell: (text, _index) => {
        return {
          style: { color: parseInt(text.tags) === 0 ? 'transparent' : 'green' },
        };
      },
      render: (_index) => {
        return {
          children: <CircleIcons />,
        };
      },
    },
    {
      title: 'Broj parcele',
      dataIndex: 'broj_parcele',
      key: 'broj_parcele',
      width: 70,
      align: 'center',
      className: 'broj-parcele-custom',
      sorter: (a, b) =>
        a.broj_parcele.localeCompare(b.broj_parcele, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
    },
    {
      title: 'Naziv parcele',
      dataIndex: 'naziv_parcele',
      key: 'naziv_parcele',
      align: 'center',
      sorter: (a, b) => a.naziv_parcele.localeCompare(b.naziv_parcele),
    },
    {
      title: (
        <span>
          Površina
          <br /> parcele (m<sup>2</sup>)
        </span>
      ),
      dataIndex: 'povrsina_parcele',
      key: 'povrsina_parcele',
      width: 100,
      align: 'center',
      sorter: (a, b) => a.povrsina_parcele - b.povrsina_parcele,
    },
    {
      title: () => (spnp === 'np' ? 'Način korištenja' : 'Kultura'),
      dataIndex: 'nacin_koristenja',
      key: 'nacin_koristenja',
      align: 'center',
      sorter: (a, b) => a.nacin_koristenja.localeCompare(b.nacin_koristenja),
    },
    {
      title: (
        <span>
          Površina dijela
          <br /> parcele (m<sup>2</sup>)
        </span>
      ),
      dataIndex: 'povrsina_dijela_parcele',
      key: 'povrsina_dijela_parcele',
      width: 120,
      align: 'center',
      sorter: (a, b) => a.povrsina_dijela_parcele - b.povrsina_dijela_parcele,
    },
  ];

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table
        columns={columns}
        dataSource={dataTable}
        locale={locale}
        className="a-list-table"
        size="small"
        scroll={{ y: 400 }}
        pagination={false}
        sticky
        onRow={(record) => ({
          onClick: () => {
            selectedParcelaFromRow(record.broj_parcele);
          },
        })}
        summary={(pageData) => {
          let totalPovrsinaDijelaParcele = 0;
          pageData.forEach(({ povrsina_dijela_parcele }) => {
            totalPovrsinaDijelaParcele += povrsina_dijela_parcele;
          });
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={5} align="right">
                  <Text>Ukupna površina parcela</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center">
                  <Text>{totalPovrsinaDijelaParcele}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </ConfigProvider>
  );
};

const mapStateToProps = (state) => ({
  parcelaGrunt: state.parcele.parcelaGrunt,
  parcelaKatastar: state.parcele.parcelaKatastar,
  optionsGru: state.parcele.parcelaGru,
  optionsKat: state.parcele.parcelaKat,
  activePls: state.pls.activePls,
});

const mapDispatchToProps = (dispatch) => ({
  setParcelaGrunt: (parcG) => dispatch(setParcelaGrunt(parcG)),
  setParcelaKatastar: (parcK) => dispatch(setParcelaKatastar(parcK)),
  setOptionsGru: (optionG) => dispatch(setOptionsGru(optionG)),
  setOptionsKat: (optionK) => dispatch(setOptionsKat(optionK)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableAList);
