import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import api from '../../util/api';
import './printexport.styles.scss';

const PrintExport = ({ currentPlsOd, currentPlsDo, currentRadilisteNovi }) => {
  // * GET podataka ID tabela sa siframa opcina
  const [dataPrintExportAPI, setDataPrintExportAPI] = useState([]);
  const [sifraKoNp, setSifraKoNp] = useState();
  const [sifraKoSp, setSifraKoSp] = useState();

  useEffect(() => {
    if (
      currentPlsOd !== null &&
      currentPlsDo !== null &&
      currentRadilisteNovi !== ''
    ) {
      try {
        api
          .get(
            `/table/lista_pl/?limit=10000&where=broj >= ${currentPlsOd} and broj <= ${currentPlsDo} and kop_ko="${currentRadilisteNovi}"`
          )
          .then((response) => {
            const result = response.data.data;
            if (result === 'No data.' || !result) {
              setDataPrintExportAPI([]);
            } else {
              setDataPrintExportAPI(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
      // GET sifra opcine NP
      try {
        api
          .get(
            `/table/kop_ko/?columns=sifra_opcine&where=naziv="${currentRadilisteNovi}"`
          )
          .then((response) => {
            const result = response.data.data[0].sifra_opcine;
            if (result !== 'No data.' && result) {
              setSifraKoNp(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
      // GET sifra opcine SP
      try {
        api
          .get(`/table/gru_ko/?columns=sifra_opcine, naziv`)
          .then((response) => {
            const result = response.data.data;
            if (result !== 'No data.' && result) {
              setSifraKoSp(result);
            }
          });
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }, [currentPlsOd, currentPlsDo, currentRadilisteNovi]);

  // * Priprema podataka za table body
  const BodyData = () =>
    dataPrintExportAPI.map((item, i) => {
      let broj_parc_sp = item.broj_sp.split('/');
      let broj_parc_np = item.broj_np.split('/');
      let vrsta_number;
      if (item.vrsta === 'Čl. 88, stav 1 ZZK' ? (vrsta_number = 1) : '');
      if (item.vrsta === 'Čl. 88, stav 2 ZZK' ? (vrsta_number = 2) : '');
      if (item.vrsta === 'Čl. 73, stav 1 ZZK' ? (vrsta_number = 3) : '');
      if (item.vrsta === 'Čl. 63-67 ZZK' ? (vrsta_number = 4) : '');

      let foundSifraSp = sifraKoSp.find((x) => x.naziv === item.gru_ko);

      return (
        <tr key={i}>
          <td>{item.broj}</td>
          <td>{vrsta_number}</td>
          <td>{foundSifraSp.sifra_opcine}</td>
          <td>{item.zk_ul}</td>
          <td>{broj_parc_sp[0]}</td>
          <td>
            {broj_parc_sp.length === 1
              ? ''
              : broj_parc_sp[1] !== ''
              ? broj_parc_sp[1]
              : ''}
          </td>
          <td>{item.povrsina_sp}</td>
          <td>{sifraKoNp}</td>
          <td>{item.posjedovni_list}</td>
          <td>{broj_parc_np[0]}</td>
          <td>
            {broj_parc_np.length === 1
              ? ''
              : broj_parc_np[1] !== ''
              ? broj_parc_np[1]
              : ''}
          </td>
          <td>{item.povrsina_np}</td>
          <td>{item.razlika_povrsina}</td>
          <td>{item.c_list}</td>
          <td>{item.c_list_rbr}</td>
        </tr>
      );
    });

  return (
    <div className='container table-export'>
      <div className='table-responsive'>
        <table
          id='idtabela'
          className='table table-sm table-borderless table-striped table-hover border-dark'>
          <thead className='header align-middle text-center bg-gray'>
            <tr>
              <th scope='col'>Prijavni_list</th>
              <th scope='col'>Pol_sit</th>
              <th scope='col'>KO_SP</th>
              <th scope='col'>ZK_UL</th>
              <th scope='col'>Broj_SP</th>
              <th scope='col'>Podbroj_SP</th>
              <th scope='col'>Površina_SP</th>
              <th scope='col'>KO_NP</th>
              <th scope='col'>Posjedovni_List</th>
              <th scope='col'>Broj_NP</th>
              <th scope='col'>Podbroj_NP</th>
              <th scope='col'>Površina_NP</th>
              <th scope='col'>Razlika</th>
              <th scope='col'>C_List</th>
              <th scope='col'>C_List_Rbr.</th>
            </tr>
          </thead>
          {currentPlsOd !== null && currentPlsDo !== null ? (
            <tbody className='align-middle text-center'>
              <BodyData />
            </tbody>
          ) : (
            <tbody></tbody>
          )}
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentPlsOd: state.pls.currentPlsOd,
  currentPlsDo: state.pls.currentPlsDo,
  currentRadilisteNovi: state.radiliste.currentRadilisteNovi,
  currentRadilisteStari: state.radiliste.currentRadilisteStari,
});

export default connect(mapStateToProps)(PrintExport);
