// Object contains subObject
const partialContains = (object, subObject) => {
  // Create arrays of property names
  const objProps = Object.getOwnPropertyNames(object);
  const subProps = Object.getOwnPropertyNames(subObject);
  if (subProps.length > objProps.length) {
    return false;
  }
  for (const subProp of subProps) {
    if (!object.hasOwnProperty(subProp)) {
      return false;
    }
    if (object[subProp] !== subObject[subProp]) {
      return false;
    }
  }
  return true;
};

export default partialContains;
