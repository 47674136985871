import React from 'react';
import { Table, ConfigProvider } from 'antd';

import { ReactComponent as CircleIcons } from '../../assets/img/circle-solid.svg';
import { locale } from '../../locale/bs_BA';
import { customizeRenderEmpty } from '../../util/customizeEmpty';

import 'antd/dist/antd.min.css';
import './antd-table-a-list-pregled.styles.scss';

const TableAListPregled = ({ dataTable, spnp }) => {
  // * Pagination length for total & show total
  let all = dataTable.length;
  const showTotal = (total) => `Total ${total}`;

  // * Table Columns definitions

  const columns = [
    {
      title: <CircleIcons className="tackica" />,
      dataIndex: 'tags',
      key: 'tags',
      className: 'tags',
      width: 35,
      align: 'center',
      sorter: { compare: (a, b) => a.tags - b.tags, multiple: 5 },
      onCell: (text, _index) => {
        return {
          style: { color: parseInt(text.tags) === 0 ? 'transparent' : 'green' },
        };
      },
      render: (_index) => {
        return {
          children: <CircleIcons className="tackica" />,
        };
      },
    },
    {
      title: 'Broj parcele',
      dataIndex: 'broj_parcele',
      key: 'broj_parcele',
      width: 130,
      align: 'center',
      className: 'broj-parcele-custom',
      sorter: {
        compare: (a, b) =>
          a.broj_parcele.localeCompare(b.broj_parcele, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        multiple: 4,
      },
      // ...getColumnSearchProps('broj parcele'),
    },
    {
      title: 'Naziv parcele',
      dataIndex: 'naziv_parcele',
      key: 'naziv_parcele',
      align: 'center',
      sorter: {
        compare: (a, b) => a.naziv_parcele.localeCompare(b.naziv_parcele),
        multiple: 3,
      },
      // ...getColumnSearchProps('naziv parcele'),
    },
    {
      title: (
        <span>
          Površina
          <br /> parcele (m<sup>2</sup>)
        </span>
      ),
      dataIndex: 'povrsina_parcele',
      key: 'povrsina_parcele',
      width: 100,
      align: 'center',
      sorter: {
        compare: (a, b) => a.povrsina_parcele - b.povrsina_parcele,
        multiple: 1,
      },
    },
    {
      title: () => (spnp === 'np' ? 'Način korištenja' : 'Kultura'),
      dataIndex: 'nacin_koristenja',
      key: 'nacin_koristenja',
      align: 'center',
      sorter: (a, b) => a.nacin_koristenja.localeCompare(b.nacin_koristenja),
    },
    {
      title: (
        <span>
          Površina dijela
          <br /> parcele (m<sup>2</sup>)
        </span>
      ),
      dataIndex: 'povrsina_dijela_parcele',
      key: 'povrsina_dijela_parcele',
      width: 120,
      align: 'center',
      sorter: {
        compare: (a, b) => a.povrsina_dijela_parcele - b.povrsina_dijela_parcele,
        multiple: 2,
      },
    },
  ];

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table
        columns={columns}
        dataSource={dataTable}
        locale={locale}
        sticky
        className="pregled-table"
        size="small"
        scroll={{ y: 'calc(100vh - 15.95rem)' }}
        showSorterTooltip={true}
        pagination={{
          locale: { ...locale },
          position: ['bottomCenter'],
          defaultPageSize: 100,
          pageSizeOptions: [20, 50, 100, 200, 500, 1000, all],
          showQuickJumper: true,
          showTotal,
        }}
      />
    </ConfigProvider>
  );
};

export default TableAListPregled;
