import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { setCurrentPlsOd, setCurrentPlsDo } from '../../redux/pls/pls.actions';

import api from '../../util/api';
import handleExportPrijavniList from '../../util/exportPrijavniList';
import customStyles from '../../util/selectCustomStyle';
import { ToastBottom } from '../swal-modal-toast/swal-modal-toast.components';

import { ReactComponent as PrintIcon } from '../../assets/img/file-pdf-duotone.svg';
import { ReactComponent as ExcelIcon } from '../../assets/img/file-excel-duotone.svg';

import './print-export-prijavni-list.styles.scss';

const PrintExportPrijavniList = ({
  currentPlsOd,
  currentPlsDo,
  setCurrentPlsOd,
  setCurrentPlsDo,
  currentRadilisteNovi,
}) => {
  const [optionsPls, setOptionsPls] = useState([]);

  // ! promjena
  // * GET option liste za Select PL
  useEffect(() => {
    let tempOptionsPls = [];
    try {
      api
        .get(
          `/table/prijavni_list_select/?limit=10000&where=kat_ko="${currentRadilisteNovi}"`
        )
        .then((response) => {
          const result = response.data.data;
          if (result !== 'No data.' && result) {
            result.map((opt) => {
              return tempOptionsPls.push({ value: opt.broj, label: opt.broj });
            });
            setOptionsPls(tempOptionsPls);
          }
        });
    } catch (err) {
      console.log('error: ', err);
    }
  }, [currentRadilisteNovi]);

  // * Set value for default selection PL za reset
  const [selectedValueOd, setSelectedValueOd] = useState(null);
  const [selectedValueDo, setSelectedValueDo] = useState(null);

  // * HandleChangeOdabirOd event for print/export Od
  const handleChangeOdabirOd = (eventod) => {
    if (currentPlsDo === null) {
      setSelectedValueOd(eventod.value);
      setCurrentPlsOd(eventod.value);
    }
    if (eventod.value <= currentPlsDo) {
      setSelectedValueOd(eventod.value);
      setCurrentPlsOd(eventod.value);
    }
    if (currentPlsDo !== null) {
      if (eventod.value > currentPlsDo) {
        ToastBottom.fire({
          icon: 'error',
          title: 'Odabir OD:',
          text: `${eventod.value} ne može biti odabran, jer je krajnji odabir ${currentPlsDo}!`,
        });
      }
    }
  };

  // * HandleChangeOdabirDo event for print/export Do
  const handleChangeOdabirDo = (eventdo) => {
    if (currentPlsOd === null) {
      setSelectedValueDo(eventdo.value);
      setCurrentPlsDo(eventdo.value);
    }
    if (eventdo.value >= currentPlsOd) {
      setSelectedValueDo(eventdo.value);
      setCurrentPlsDo(eventdo.value);
    }
    if (currentPlsOd !== null) {
      if (eventdo.value < currentPlsOd) {
        ToastBottom.fire({
          icon: 'error',
          title: 'Odabir DO:',
          text: `${eventdo.value} ne može biti odabran, jer je poćetni odabir ${currentPlsOd}!`,
        });
      }
    }
  };

  // * Na refresh da setuje null da se ocisti redax state koji popunjava tabelu
  useEffect(() => {
    if (selectedValueOd === null) {
      setCurrentPlsOd(null);
    }
  }, [selectedValueOd, setCurrentPlsOd]);

  useEffect(() => {
    if (selectedValueDo === null) {
      setCurrentPlsDo(null);
    }
  }, [selectedValueDo, setCurrentPlsDo]);

  return (
    <div className='col-auto ms-2 me-auto'>
      <div className='d-flex justify-content-between align-items-center'>
        <p className='fw-bold ms-5 mb-0'>Odabir od:</p>
        <div className={`select-wrapper-od-do ms-2 print-od`}>
          <Select
            placeholder='od'
            styles={customStyles}
            width='120px'
            menuColor='#333'
            value={optionsPls.find((obj) => obj.value === selectedValueOd)}
            options={optionsPls}
            noOptionsMessage={() => 'Unesite broj za pretragu'}
            onChange={handleChangeOdabirOd}
          />
        </div>
        <p className='fw-bold ms-2 mb-0'>do:</p>
        <div className={`select-wrapper-od-do ms-2 print-do`}>
          <Select
            placeholder='do'
            styles={customStyles}
            width='120px'
            menuColor='#333'
            value={optionsPls.find((obj) => obj.value === selectedValueDo)}
            options={optionsPls}
            noOptionsMessage={() => 'Unesite broj za pretragu'}
            onChange={handleChangeOdabirDo}
          />
        </div>
        <div className={`print-button disabled`}>
          <button className={`btn btn-outline-danger ms-3 disabled`}>
            Kreiraj <PrintIcon className='print-icons' />
          </button>
        </div>
        <div
          className={`export-button ${
            currentPlsOd === null || currentPlsDo === null ? 'disabled' : ''
          }`}>
          <button
            className={`btn btn-outline-success ms-3 ${
              currentPlsOd === null || currentPlsDo === null ? 'disabled' : ''
            }`}
            onClick={() => {
              handleExportPrijavniList(
                currentRadilisteNovi,
                currentPlsOd,
                currentPlsDo
              );
            }}>
            Export <ExcelIcon className='excel-icons' />
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentPlsOd: state.pls.currentPlsOd,
  currentPlsDo: state.pls.currentPlsDo,
  currentRadilisteNovi: state.radiliste.currentRadilisteNovi,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPlsOd: (plod) => dispatch(setCurrentPlsOd(plod)),
  setCurrentPlsDo: (pldo) => dispatch(setCurrentPlsDo(pldo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintExportPrijavniList);
