import React from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { Tooltip, Button } from 'antd';
import axios from 'axios';
import config from '../../config/host';
import 'antd/dist/antd.min.css';

import { setLastPL, setIdentKat, setIdentGru, setStatusKat, setStatusGru } from '../../redux/pls/pls.actions';

import api from '../../util/api';
import { onDeleteTableActionButton } from '../../util/deletePrijavniList';
import { ReactComponent as IconsTimesCircle } from '../../assets/img/times-circle-light.svg';
import './table-action-button.styles.scss';

const TableActionButton = ({
  itemId,
  setLastPL,
  setIdentKat,
  setIdentGru,
  setStatusKat,
  setStatusGru,
  currentRadilisteNovi,
  currentRadilisteStari,
}) => {
  const handleDelete = (item, novi, stari) => {
    const deleteSwal = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success me-3',
        cancelButton: 'btn btn-danger ms-3',
      },
      buttonsStyling: false,
    });
    deleteSwal
      .fire({
        title: 'Da li ste sigurni?',
        text: 'Nećete biti u mogućnosti vratiti ovu akciju!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Da, izbriši`,
        cancelButtonText: `Ne, odustani`,
        reverseButtons: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const handleResult = async (results) => {
            let podaciUpdateTabele = results.data.data;
            let { status, error } = results;
            if (error) {
              deleteSwal.fire({
                icon: 'error',
                title: 'Došlo je do greške prilikom brisanja',
              });
              return;
            }
            if (status === 200) {
              deleteSwal.fire({
                icon: 'success',
                title: 'Izbrisano! ',
                text: 'Vaši podaci su izbrisani.',
                showConfirmButton: false,
                timer: 2000,
              });
              // Vracam podatke za setLastPL preko ove funkcije proslijedjujem response
              onDeleteItem(podaciUpdateTabele);
              // Poziv za refresh podataka nakon delete za identKat, identGru
              onDeleteUpdateIdentKat();
              onDeleteUpdateIdentGru();
              onDeleteUpdateStatus();
            }
          };

          // Poziv API funkcije iz util za delete i setLastPL kroz handleResult
          onDeleteTableActionButton(item, novi, stari).then((data) => {
            handleResult(data);
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          deleteSwal.fire({
            icon: 'info',
            title: 'Otkazano! ',
            text: 'Vaši podaci su sigurni.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
  };

  const onDeleteItem = (itemId) => {
    setLastPL(itemId);
  };

  // ! LIMIT
  const onDeleteUpdateIdentKat = () => {
    try {
      api
        .get(`/table/prijavni_list_ident_kat/?limit=10000&columns=broj, parc&where=ko="${currentRadilisteNovi}"`)
        .then((response) => {
          const result = response.data.data;
          // result !== 'No data.' && result
          if (result) {
            setIdentKat(result);
          }
        });
    } catch (err) {
      console.log('error: ', err);
    }
  };
  // ! LIMIT
  const onDeleteUpdateIdentGru = () => {
    try {
      api
        .get(`/table/prijavni_list_ident_gru/?limit=10000&columns=broj, parc&where=gko="${currentRadilisteStari}"`)
        .then((response) => {
          const result = response.data.data;
          // result !== 'No data.' && result
          if (result) {
            setIdentGru(result);
          }
        });
    } catch (err) {
      console.log('error: ', err);
    }
  };

  const onDeleteUpdateStatus = () => {
    // Ne dirati redoslijed od njega zavisi prikaz
    let one = `${config.API_HOST}table/prijavni_list/totalSize?where=kat_ko="${currentRadilisteNovi}"`;
    let two = `${config.API_HOST}table/prijavni_list_statistika_kat?where=ko="${currentRadilisteNovi}"`;
    let tree = `${config.API_HOST}table/prijavni_list/totalSize?where=gru_ko="${currentRadilisteStari}"`;
    let four = `${config.API_HOST}table/prijavni_list_statistika_gru?where=ko="${currentRadilisteStari}"`;
    let five = `${config.API_HOST}table/prijavni_list_ident_gru/?limit=99999&columns=broj, parc&where=ko="${currentRadilisteNovi}"`;
    let six = `${config.API_HOST}table/gru_a_list/totalSize?where=ko="${currentRadilisteStari}"`;
    let seven = `${config.API_HOST}table/kop_a_list/totalSize?where=ko="${currentRadilisteNovi}"`;

    const reqOne = axios.get(one);
    const reqTwo = axios.get(two);
    const reqTree = axios.get(tree);
    const reqFour = axios.get(four);
    const reqFive = axios.get(five);
    const reqSix = axios.get(six);
    const reqSeven = axios.get(seven);
    try {
      axios
        .all([reqOne, reqTwo, reqTree, reqFour, reqFive, reqSix, reqSeven])
        .then(
          axios.spread((...response) => {
            const resultKat1 = response[0].data;
            const resultKat2 = response[1].data.data[0];
            const resultGru1 = response[2].data;
            const resultGru2 = response[3].data.data[0];
            const resultGru3 = response[4].data.data.length;
            const resultGru4 = response[5].data;
            const resultKat3 = response[6].data;

            const joinKat = [resultKat1, resultKat2, resultKat3];
            const joinGru = [resultGru1, resultGru2, resultGru3, resultGru4];
            if (
              resultKat1 !== 'No data.' &&
              resultKat1 &&
              resultKat2 !== 'No data.' &&
              resultKat2 &&
              resultKat3 !== 'No data.' &&
              resultKat3
            ) {
              setStatusKat(joinKat);
            }
            if (
              resultGru1 !== 'No data.' &&
              resultGru1 &&
              resultGru2 !== 'No data.' &&
              resultGru2 &&
              resultGru3 !== 'No data.' &&
              resultGru3 &&
              resultGru4 !== 'No data.' &&
              resultGru4
            ) {
              setStatusGru(joinGru);
            }
          }),
        )
        .catch((error) => {
          console.log('error: ', error);
        });
    } catch (err) {
      console.log('error: ', err);
    }
  };

  return (
    <Tooltip placement="left" title="Brisanje ovog unos" color="red" key={itemId}>
      <Button
        type="link"
        size="small"
        shape="circle"
        danger
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(itemId, currentRadilisteNovi, currentRadilisteStari);
        }}
      >
        <IconsTimesCircle className="times-circle" />
      </Button>
    </Tooltip>
  );
};

const mapStateToProps = (state) => ({
  lastPL: state.lastPL,
  currentPls: state.pls.currentPls,
  currentRadilisteStari: state.radiliste.currentRadilisteStari,
  currentRadilisteNovi: state.radiliste.currentRadilisteNovi,
  identKat: state.pls.identKat,
  identGru: state.pls.identGru,
  statusKat: state.pls.statusKat,
  statusGru: state.pls.statusGru,
});

const mapDispatchToProps = (dispatch) => ({
  setLastPL: (updatePl) => dispatch(setLastPL(updatePl)),
  setIdentKat: (identkatupdate) => dispatch(setIdentKat(identkatupdate)),
  setIdentGru: (identgruupdate) => dispatch(setIdentGru(identgruupdate)),
  setStatusKat: (statuskatupdate) => dispatch(setStatusKat(statuskatupdate)),
  setStatusGru: (statusgruupdate) => dispatch(setStatusGru(statusgruupdate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableActionButton);
