import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
import radilisteReducer from "./radiliste/radiliste.reducer";
import kopplReducer from "./koppl/koppl.reducer";
import gruzkReducer from "./gruzk/gruzk.reducer";
import plsReducer from "./pls/pls.reducer";
import parceleReducer from "./parcele/parcele.reducer";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  koppl: kopplReducer,
  gruzk: gruzkReducer,
  pls: plsReducer,
  radiliste: radilisteReducer,
  parcele: parceleReducer,
});

export default persistReducer(persistConfig, rootReducer);
