import { PlsActionsTypes } from './pls.types';

const INITIAL_STATE = {
  activePls: null,
  currentPls: null,
  currentPlsOd: null,
  currentPlsDo: null,
  lastPL: [],
  identKat: [],
  identGru: [],
  statusKat: [],
  statusGru: [],
};

const plsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PlsActionsTypes.SET_ACTIVE_PLS:
      return {
        ...state,
        activePls: action.payload,
      };
    case PlsActionsTypes.SET_CURRENT_PLS:
      return {
        ...state,
        currentPls: action.payload,
      };
    case PlsActionsTypes.SET_CURRENT_PLSOD:
      return {
        ...state,
        currentPlsOd: action.payload,
      };
    case PlsActionsTypes.SET_CURRENT_PLSDO:
      return {
        ...state,
        currentPlsDo: action.payload,
      };
    case PlsActionsTypes.ADD_LAST_PL:
      if (action.payload !== 'No data.') {
        return {
          ...state,
          lastPL: action.payload,
        };
      }
      return { ...state, lastPL: [] };
    case PlsActionsTypes.SET_IDENT_KAT:
      if (action.payload !== 'No data.') {
        return {
          ...state,
          identKat: action.payload,
        };
      }
      return { ...state, identKat: [] };
    case PlsActionsTypes.SET_IDENT_GRU:
      if (action.payload !== 'No data.') {
        return {
          ...state,
          identGru: action.payload,
        };
      }
      return { ...state, identGru: [] };
    case PlsActionsTypes.SET_STATUS_KAT:
      if (action.payload !== 'No data.') {
        return {
          ...state,
          statusKat: action.payload,
        };
      }
      return { ...state, statusKat: [] };
    case PlsActionsTypes.SET_STATUS_GRU:
      if (action.payload !== 'No data.') {
        return {
          ...state,
          statusGru: action.payload,
        };
      }
      return { ...state, statusGru: [] };
    default:
      return state;
  }
};

export default plsReducer;
