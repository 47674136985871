import { KopplActionsTypes } from './koppl.types';

export const setCurrentKoppl = (koppl) => ({
  type: KopplActionsTypes.SET_CURRENT_KOPPL,
  payload: koppl,
});

export const setUpdateKoppl = (koppl) => ({
  type: KopplActionsTypes.UPDATE_KOPPL,
  payload: koppl,
});
