import { GruzkActionsTypes } from './gruzk.types';

const INITIAL_STATE = {
  collectionsGruzk: [],
};

const gruzkReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GruzkActionsTypes.SET_CURRENT_GRUZK:
      return {
        ...state,
        currentGruzk: action.payload,
      };
    case GruzkActionsTypes.UPDATE_GRUZK:
      if (action.payload !== 'No data.') {
        return {
          ...state,
          collectionsGruzk: action.payload,
        };
      }
      return { ...state, collectionsGruzk: [] };
    default:
      return state;
  }
};

export default gruzkReducer;
