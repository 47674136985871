import { ParceleActionTypes } from './parcele.types';

const INITIAL_STATE = {
  parcelaKatastar: '',
  parcelaGrunt: '',
  optionsKat: [],
  optionsGru: [],
};

const parceleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ParceleActionTypes.SET_PARCELA_KATASTAR:
      return {
        ...state,
        parcelaKatastar: action.payload,
      };
    case ParceleActionTypes.SET_PARCELA_GRUNT:
      return {
        ...state,
        parcelaGrunt: action.payload,
      };
    case ParceleActionTypes.SET_OPTIONS_KAT:
      if (action.payload !== 'No data.') {
        return {
          ...state,
          optionsKat: action.payload,
        };
      }
      return { ...state, optionsKat: action.payload };
    case ParceleActionTypes.SET_OPTIONS_GRU:
      if (action.payload !== 'No data.') {
        return {
          ...state,
          optionsGru: action.payload,
        };
      }
      return { ...state, optionsGru: [] };
    default:
      return state;
  }
};

export default parceleReducer;
