import axios from 'axios';
let API_URL = 'https://app.doc.ba/api/table/prijavni_list/';

export async function onDeleteTableActionButton(
  itemId,
  currentRadilisteNovi,
  currentRadilisteStari
) {
  return await axios({
    method: 'DELETE',
    url: `${API_URL}${itemId}?limit=99999&where=kat_ko="${currentRadilisteNovi}" and gru_ko="${currentRadilisteStari}"`,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err.response);
    });
}
