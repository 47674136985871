import { RadilisteActionsTypes } from './radiliste.types';

const INITIAL_STATE = {
  currentRadilisteStari: '',
  currentRadilisteNovi: '',
  visibleDrawer: false,
};

const radilisteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RadilisteActionsTypes.SET_CURRENT_RADILISTE_NOVI:
      return {
        ...state,
        currentRadilisteNovi: action.payload,
      };
    case RadilisteActionsTypes.SET_CURRENT_RADILISTE_STARI:
      return {
        ...state,
        currentRadilisteStari: action.payload,
      };
    case RadilisteActionsTypes.SET_VISIBLE_DRAWER:
      return {
        ...state,
        visibleDrawer: action.payload,
      };
    default:
      return state;
  }
};

export default radilisteReducer;
