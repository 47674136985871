import { ParceleActionTypes } from './parcele.types';

export const setParcelaKatastar = (parcelaK) => ({
  type: ParceleActionTypes.SET_PARCELA_KATASTAR,
  payload: parcelaK,
});

export const setParcelaGrunt = (parcelaG) => ({
  type: ParceleActionTypes.SET_PARCELA_GRUNT,
  payload: parcelaG,
});

export const setOptionsKat = (optionK) => ({
  type: ParceleActionTypes.SET_OPTIONS_KAT,
  payload: optionK,
});

export const setOptionsGru = (optionG) => ({
  type: ParceleActionTypes.SET_OPTIONS_GRU,
  payload: optionG,
});
