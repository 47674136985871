import ExcellentExport from 'excellentexport';

const handleExportPrijavniList = (novi, plod, pldo) => {
  let options = {
    // anchor: // ? String or HTML Element,
    openAsDownload: true, // ? Use this options if not using an anchor tag
    format: 'xlsx', // ? 'xlsx'/'xls'/'csv'
    filename: `IDTabela-${novi}_${plod}_${pldo}`, // ? String
  };

  let sheets = [
    {
      name: 'Sheet 1', // ? Sheet name
      from: {
        // TODO: moram ovdje props ubaciti
        table: 'idtabela', // ? Table ID or table element
        // array: [
        //   [1, 2, 3],
        //   [4, 5, 6],
        // ],  // ? Array with the data. Array where each element is a row. Every row is an array of the cells.
        // arrayHasHeader: true, // ? Array first row is the header
        // removeColumns: [...], // ? Array of column indexes (from 0)
        // filterRowFn: function(row) {return true}, // ? Function to decide which rows are returned
        // fixValue: function(value, row, column) {return fixedValue} // ? Function to fix values, receiving value, row num, column num
        // fixArray: function(array) {return array} // ? Function to manipulate the whole data array
      },
    },
  ];

  ExcellentExport.convert(options, sheets);
};

export default handleExportPrijavniList;
