import { KopplActionsTypes } from './koppl.types';

const INITIAL_STATE = {
  collectionsKoppl: [],
};

const kopplReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case KopplActionsTypes.SET_CURRENT_KOPPL:
      return {
        ...state,
        currentKoppl: action.payload,
      };
    case KopplActionsTypes.UPDATE_KOPPL:
      if (action.payload !== 'No data.') {
        return {
          ...state,
          collectionsKoppl: action.payload,
        };
      }
      return { ...state, collectionsKoppl: [] };
    default:
      return state;
  }
};

export default kopplReducer;
