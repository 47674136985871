// * Select custom style
const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.selectProps.menuColor,
    backgroundColor: '#fff',
    padding: 3,
    zIndex: 9999,
    fontWeight: 600,
  }),
  option: (styles, state) => ({
    ...styles,
    cursor: 'pointer',
  }),
  control: (base) => ({
    ...base,
    height: '35px',
    minHeight: '35px',
    cursor: 'pointer',
  }),
  valueContainer: (base) => ({
    ...base,
    height: '32px',
    padding: '0 6px',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: '32px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    display: state.selectProps.menuIsOpen ? 'none' : 'block',
    height: '22px',
    color: '#212529',
  }),
};

export default customStyles;
