import React from 'react';

import { Table, ConfigProvider } from 'antd';
import 'antd/dist/antd.min.css';

import { locale } from '../../locale/bs_BA';
import { customizeRenderEmpty } from '../../util/customizeEmpty';

import './antd-table-b-list.styles.scss';

const TableBList = ({ dataTable }) => {
  // * Table Columns definitions
  const columns = [
    {
      title: 'Naziv',
      dataIndex: 'naziv',
      key: 'naziv',
      align: 'center',
      sorter: (a, b) => {
        a = a.naziv || '';
        b = b.naziv || '';
        return a.localeCompare(b); // ako imam null u podacima da ne breack app
      },
    },
    {
      title: 'Adresa',
      dataIndex: 'adresa',
      key: 'adresa',
      align: 'center',
      sorter: (a, b) => {
        a = a.adresa || '';
        b = b.adresa || '';
        return a.localeCompare(b); // ako imam null u podacima da ne breack app
      },
    },
    {
      title: 'Udio',
      dataIndex: 'udio',
      key: 'udio',
      width: 120,
      align: 'center',
      sorter: (a, b) =>
        a.udio.localeCompare(b.udio, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
    },
  ];

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table
        columns={columns}
        dataSource={dataTable}
        locale={locale}
        className="b-list-table"
        size="small"
        scroll={{ y: 400 }}
        pagination={false}
        sticky
      />
    </ConfigProvider>
  );
};

export default TableBList;
